.criteria_time_picker {
    display: flex;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    height: 30px;
    width: 98px;
    
}

.criteria_time_picker .ant-picker {
    border-radius: 4px !important;
}

.criteria_time_picker .ant-picker:hover {
    background: rgba(var(--dl-color-text-black), 0.03) !important;
}

.criteria_time_picker .ant-picker input {
    letter-spacing: 0.3px !important;
}