.criteria_condition {
	column-gap: 10px;
	row-gap: 4px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.criteria_condition::-webkit-scrollbar {
	display: none;
}

.criteria_category_wrapper {
	gap: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.criteria_category_type_box {
	gap: 8px;
	height: 30px;
	display: flex;
	position: relative;
	align-items: center;
	border-width: 1px;
	padding: 0px 8px;
	border-radius: 4px;
	flex-direction: row;
	justify-content: center;
	border-bottom-width: 1px;
	cursor: pointer;
}

.criteria_category_type_box:hover {
	background-color: rgba(var(--dl-color-text-black), 0.05);
}

.criteria_category_type_text {
	font-size: 13px;
	letter-spacing: 0.3px;
	white-space: nowrap;
}

.criteria_text {
	color: rgb(var(--dl-color-text-black));
	font-size: 13px;
	white-space: nowrap;
}

.criteria_condition_group {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
}

.criteria_dropdown_wrapper {
	height: 30px;
	display: flex;
	position: relative;
	align-items: flex-start;
	justify-content: flex-start;
}

.criteria_dropdown_button {
	height: 30px;
	display: flex;
	position: relative;
	align-items: center;
	border-color: rgba(var(--dl-color-text-black), 0.14);
	border-width: 1px;
	padding: 0px 4px 0px 8px;
	border-radius: 4px;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	gap: 2px;
}

.criteria_dropdown_button:hover {
	background-color: rgba(var(--dl-color-text-black), 0.05);
}

.criteria_dropdown_button_disabled {
	pointer-events: none;
    background-color: rgba(var(--dl-color-text-black), 0.05);
	padding: 0px 8px;
}

.criteria_dropdown_display_wrapper {
	display: flex;
	gap: 2px;
	align-items: center;
}

.criteria_dropdown_button_text {
	color: rgb(var(--dl-color-text-black));
	font-size: 13px;
	letter-spacing: 0.3px;
	white-space: nowrap;
}

.criteria_dropdown_button_arrow_icon {
	fill: rgba(var(--dl-color-black), 0.4);
	width: 16px;
	height: 16px;
	transition: ease .2s;
	flex-shrink: 0;
}

.criteria_dropdown_menu {
	display: flex;
	overflow: hidden;
	position: relative;
	box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
	align-items: stretch;
	flex-direction: column;
	justify-content: flex-start;
	border-radius: 6px;
	background-color: rgb(var(--dl-color-backgrounds-white-on-white));
	z-index: 1;
	padding: 4px;
	min-width: 100%;
}

.criteria_dropdown_menu_top {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}

.criteria_dropdown_menu_separator {
	height: 1px;
	background-color: rgb(var(--dl-color-borders-separator));
	margin: 0px 4px;
}

.dropdown_parameter {
	padding: 12px 16px 16px;
	overflow: visible;
}

.dropdown_news {
	width: 898px;
}

.criteria_dropdown_menu_scroll_wrapper {
	width: 100%;
	max-height: calc(90vh - 400px);
	display: flex;
	overflow: auto;
	position: relative;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	transition: ease .3s;
}

.criteria_dropdown_parameter_title_row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 14px;
	gap: 4px;
}

.criteria_dropdown_parameter_title {
	color: rgba(var(--dl-color-text-black), 0.57);
	font-weight: var(--dl-fontweight-500);
	letter-spacing: 0.3px;
	font-size: 9px;
	text-transform: uppercase;
	white-space: nowrap;
}

.criteria_dropdown_indicator_help_icon {
	width: 12px;
	height: 12px;
	cursor: pointer;
	fill: rgba(var(--dl-color-text-black), 0.57)
}

.criteria_dropdown_indicator_help_icon:hover {
	fill: rgba(var(--dl-color-text-black), 0.8)
}

.criteria_dropdown_menu_category_wrapper {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.criteria_dropdown_menu_item_wrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: stretch;
	border-radius: 4px;
	flex-shrink: 0;
	transition: .2s ease;
}

.criteria_dropdown_menu_item_wrapper:hover {
	background-color: rgb(var(--dl-color-backgrounds-hover3)) !important;
}

.criteria_dropdown_menu_item_checkbox {
	width: 10px;
	height: 10px;
	margin-left: 4px;
	border: 1px solid rgba(var(--dl-color-text-black), 0.3);
	background-color: rgb(var(--dl-color-backgrounds-hover2));
	border-radius: 2px;
	display: flex;
	cursor: default;
}

.criteria_dropdown_menu_item_checkbox_inner {
	width: 100%;
	height: 100%;
	border: 1px solid rgb(var(--dl-color-backgrounds-hover2));
	background-color: rgb(var(--dl-color-backgrounds-hover2));
	border-radius: 2px;
}

.criteria_dropdown_menu_item {
	margin: 6px;
	font-size: 13px;
	letter-spacing: 0.3px;
	flex-shrink: 0;
	white-space: nowrap;
	text-align: start;
}

.menu_item_category {
	color: rgba(var(--dl-color-text-black), 0.82);
	font-size: 12px;
	font-weight: var(--dl-fontweight-500);
	letter-spacing: 0.2px;
	text-transform: uppercase;
}

.criteria_input_timeframe_wrapper {
	display: flex;
	position: relative;
	align-items: stretch;
	justify-content: stretch;
	gap: 4px;
}

.criteria_input_wrapper {
	height: 30px;
	display: flex;
	position: relative;
	align-items: stretch;
	justify-content: stretch;
}

.criteria_input {
	color: rgb(var(--dl-color-text-black));
	font-size: 13px;
	width: 66px;
	letter-spacing: 0.3px;
	padding: 0px 8px;
	border-color: rgba(var(--dl-color-text-black), 0.14);
	border-radius: 4px;
}

.criteria_input:hover {
	background-color: rgba(var(--dl-color-text-black), 0.05);
}

.criteria_input:focus {
	border-color: rgb(var(--dl-color-primary-100));
}

.keyword_input_wrapper {
	padding: 0px 8px;
	height: 30px;
	flex-grow: 1;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid rgb(var(--dl-color-borders-separator));
	border-radius: 4px;
	overflow-x: scroll;
	cursor: text;
}

.keyword_input_wrapper:hover {
	background-color: rgba(var(--dl-color-text-black), 0.05);
}

.keyword_input_wrapper::-webkit-scrollbar {
	display: none;
}

.keyword_input_list {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
}

.keyword_input_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	width: 185px;
	flex-shrink: 0;
	height: 20px;
}

.keyword_input {
	padding: 2px 4px;
	font-size: 13px;
	letter-spacing: 0.3px;
	color: rgb(var(--dl-color-text-black));
	border: none;
	width: auto;
	position: absolute;
	width: 100%;
	height: 100%;
}

.keyword_input_placeholder {
	font-size: 11px;
	color: rgba(var(--dl-color-text-black), 0.4);
	padding-left: 4px;
}

.keyword_input_item_wrapper {
	padding: 0px 4px;
	border-radius: 2px;
	background-color: rgba(var(--dl-color-black), 0.1);
	position: relative;
}

.keyword_input_item_text {
	font-size: 11px;
	letter-spacing: 0.3px;
	line-height: 1;
	color: rgb(var(--dl-color-text-black))
}

.keyword_input_item_delete {
	width: 10px;
	height: 10px;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	padding: 2px;
	justify-content: center;
	background-color: rgb(var(--dl-color-text-gray2));
	cursor: pointer;
	top: -4px;
	right: -6px;
}

.keyword_input_item_delete:hover {
	background-color: rgb(var(--dl-color-text-black));
}

.keyword_input_item_delete_icon {
	fill: rgb(var(--dl-color-white));
	width: 8px;
	height: 8px;
}

.criteria_popup_input {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
}

.criteria_popup_input_title {
	font-size: 13px;
	letter-spacing: 0.3px;
	flex-shrink: 0;
}

.up_down_icons {
	position: absolute;
	right: 4px;
	top: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2px;
}

.up_down_icon_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10px;
	overflow: hidden;
	border-radius: 2px;
	width: 16px;
}

.up_down_icon_wrapper:hover {
	background-color: rgba(var(--dl-color-text-black), 0.05);
}

.icon_wrapper_no_hover:hover {
	background-color: transparent;
	height: 8px;
	width: 14px;
}

.up_down_icon {
	width: 16px;
	height: 16px;
	flex-shrink: 0;
	fill: rgba(var(--dl-color-black), 0.4);
}

.criteria_input_switch {
	border: 1px solid rgb(var(--dl-color-borders-separator));
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: stretch;
	justify-content: flex-start;
	height: 30px;
	overflow: hidden;
}

.criteria_input_switch:hover {
	background-color: rgba(var(--dl-color-text-black), 0.05);
}

.criteria_input_switch_box {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: stretch;
	justify-content: flex-start;
	transition: ease .2s;
}

.criteria_input_switch_item_wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 8px;
	height: 28px;
	flex-shrink: 0;
}

.criteria_input_switch_item {
	color: rgb(var(--dl-color-text-black));
	font-size: 13px;
	letter-spacing: 0.3px;
}

.search_wrapper {
	gap: 4px;
	display: flex;
	position: relative;
	align-items: center;
	padding-left: 20px;
	padding-right: 24px;
	justify-content: flex-start;
	height: 20px;
	margin: 4px;
}

.search_icon {
	fill: rgb(var(--dl-color-text-gray4));
	left: 0px;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 2x;
}

.search_input {
	width: 100%;
	display: flex;
	align-items: flex-start;
	font-size: 12px;
	font-weight: var(--dl-fontweight-300);
	letter-spacing: 0.3px;
	border: none;
	border-radius: 0px;
	height: 16px;
}

.search_input::placeholder {
	color: rgba(var(--dl-color-text-gray4), 0.63);
}

.search_delete {
	top: 4px;
	right: 8px;
	width: 12px;
	height: 12px;
	display: flex;
	position: absolute;
	align-self: center;
	align-items: center;
	border-radius: 8px;
	padding: 2px;
	justify-content: center;
	background-color: rgb(var(--dl-color-primary-100));
	cursor: default;
}

.search_delete:hover {
	background-color: rgb(var(--dl-color-primary-300));
}

.search_delete_icon {
	fill: rgb(var(--dl-color-white-always));
	width: 9px;
	height: 9px;
}