.home_page_wrapper {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: stretch;
}

.home_page_container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-image: url('https://images.unsplash.com/photo-1686548812883-9d3777f4c137?ixid=M3w5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNjg2OTExNDU0fA&ixlib=rb-4.0.3&w=1500');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.header_h1 {
  font-size: 100px;
  color: rgb(var(--dl-color-white));
  font-weight: var(--dl-fontweight-500);
}