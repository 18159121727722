.icon_container {
    display: flex;
    position: relative;
    align-items: center;
    padding: 3px 5px;
    border-width: 1px;
    border-radius: 8px;
    justify-content: center;
}

.icon {
    fill: currentColor;
    width: 100%;
    height: 100%;
}

.layout_icon {
    gap: 1px;
    margin: 0px 1px 0px 2px;
    width: 15px;
    height: 15px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.layout_icon_horizontal_bar {
    width: 13px;
    height: 3px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    border-color: rgba(var(--dl-color-text-black), 0.6);
    border-width: 0.5px;
    border-radius: 1px;
}

.layout_icon_vertical_bars {
    gap: 2px;
    display: flex;
    align-self: center;
    align-items: flex-start;
}

.layout_icon_vertical_bar {
    width: 3px;
    height: 10px;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    border-color: rgba(var(--dl-color-text-black), 0.6);
    border-width: 0.5px;
    border-radius: 1px;
    justify-content: flex-start;
}

.resize_icon {
    cursor: n-resize;
    height: 22px;
    width: 18px;
    padding: 4px;
    border-radius: 4px;
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 2px;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    position: absolute;
    top: -9px;
    z-index: 2;
    left: calc(50% - 9px);
}

.resize_icon:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.resize_icon_bar {
    height: 1px;
    background-color: rgb(var(--dl-color-text-black));
}

.resize_icon_condition_settings {
    top: calc(50% - 11px);
    left: -10px;
    cursor: e-resize;
}