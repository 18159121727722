.recurring_picker_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    border-top: 1px solid rgb(var(--dl-color-borders-separator));
}

.recurring_picker {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.interval_picker_container {
    display: flex;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    height: 42px;
    width: 60%;
    overflow: visible;
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
    flex-shrink: 0;
}

.delete_icon {
    fill: rgba(var(--dl-color-text-black), 0.8);
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 6px;
    transition: ease .2s;
}

.delete_icon:hover {
    fill: rgba(var(--dl-color-colors-red), 0.8);
    width: 16px;
    height: 16px;
}

.interval_picker_button {
    width: 100%;
    height: 42px;
    display: flex;
    padding: 0px 10px 0px 16px;
    position: relative;
    transition: 0.3s;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    cursor: pointer;
}

.interval_picker_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.interval_picker_button_left {
    gap: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.interval_picker_button_icon {
    fill: rgba(var(--dl-color-text-black), 0.46);
    width: 14px;
    height: 14px;
}

.interval_picker_button_text_wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.interval_picker_button_text_small {
    color: rgb(var(--dl-color-text-gray2));
    font-size: 9px;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.3px;
    margin-bottom: 3px;
}

.interval_picker_button_text {
    color: rgb(var(--dl-color-text-black));
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 1;
}

.interval_picker_button_text_placeholder {
    color: rgba(var(--dl-color-text-gray2), 0.85);
    font-size: 13px;
    letter-spacing: 0.3px;
    font-weight: var(--dl-fontweight-300);
    margin-top: 2px;
}

.interval_picker_button_right {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.interval_picker_button_arrow_icon {
    fill: rgba(var(--dl-color-text-black), 0.46);
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.interval_picker_button_options_wrapper {
    padding: 4px;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border-radius: 6px;
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    width: calc(100% - 8px);
    z-index: 1;
}

.interval_picker_button_option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
}

.interval_picker_button_option:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.interval_picker_button_option_text {
    color: currentColor;
    font-size: 13px;
    letter-spacing: 0.3px;
}

.add_row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    margin-top: 8px;
}

.add_button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid rgb(var(--dl-color-borders-separator));
    cursor: pointer;
    flex-shrink: 0;
}

.add_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.add_icon {
    width: 24px;
    height: 24px;
    fill: rgb(var(--dl-color-text-black));
}

.add_text {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 11px;
}