:root {
    --dl-fontweight-100: 100;
    --dl-fontweight-200: 200;
    --dl-fontweight-300: 300;
    --dl-fontweight-400: 400;
    --dl-fontweight-500: 500;
    --dl-fontweight-600: 600;
    --dl-fontweight-700: 700;
    --dl-fontweight-800: 800;
    --dl-fontweight-900: 900;
    --dl-color-white: 255, 255, 255;
    --dl-color-white-always: 255, 255, 255;
    --dl-color-black: 0, 0, 0;
    --dl-color-black-always: 0, 0, 0;
    --dl-color-shadow: 200, 200, 200;
    --dl-color-primary-100: 65, 105, 226;
    --dl-color-primary-300: 37, 79, 206;
    --dl-color-colors-red-error: 214, 15, 15;
    --dl-color-colors-red: 173, 5, 25;
    --dl-color-colors-red-bright: 227, 39, 53;
    --dl-color-colors-red-pink: 255, 60, 125;
    --dl-color-colors-red-pink-hover: 232, 46, 108;
    --dl-color-colors-blue: 53, 114, 250;
    --dl-color-colors-pink: 150, 53, 250;
    --dl-color-colors-violet: 116, 94, 238;
    --dl-color-colors-orange: 255, 140, 60;
    --dl-color-colors-green: 34, 177, 148;
    --dl-color-colors-green-hover: 26, 161, 133;
    --dl-color-colors-green-dark: 49, 125, 124;
    --dl-color-text-black: 25, 22, 34;
    --dl-color-text-gray1: 86, 89, 97;
    --dl-color-text-gray2: 97, 99, 101;
    --dl-color-text-gray3: 105, 107, 119;
    --dl-color-text-gray4: 115, 118, 127;
    --dl-color-text-gray5: 89, 89, 89;
    --dl-color-widget-connection-blue: 37, 105, 232;
    --dl-color-widget-connection-red: 224, 49, 67;
    --dl-color-widget-connection-orange: 232, 163, 44;
    --dl-color-widget-connection-pink: 232, 44, 213;
    --dl-color-widget-connection-green: 24, 204, 39;
    --dl-color-widget-connection-none: 224, 224, 224;
    --dl-color-backgrounds-logo: 66, 69, 77;
    --dl-color-backgrounds-white: 255, 255, 255;
    --dl-color-backgrounds-white-on-white: 255, 255, 255;
    --dl-color-backgrounds-topnav: 255, 255, 255;
    --dl-color-backgrounds-gray-light: 248, 247, 253;
    --dl-color-backgrounds-gray-dark: 50, 50, 46;
    --dl-color-backgrounds-page-back: 222, 229, 231;
    --dl-color-backgrounds-page: 246, 247, 249;
    --dl-color-backgrounds-hover-light: 247, 248, 242;
    --dl-color-backgrounds-hover1: 242, 243, 245;
    --dl-color-backgrounds-hover2: 240, 241, 243;
    --dl-color-backgrounds-hover3: 232, 233, 234;
    --dl-color-backgrounds-hover-header: 247, 248, 249;
    --dl-color-backgrounds-tooltip: 43, 46, 48;
    --dl-color-backgrounds-skeleton: 224, 224, 224;
    --dl-color-backgrounds-skeleton-dark: 97, 97, 97;
    --dl-color-backgrounds-dashboard: 160, 160, 160;
    --dl-color-borders-separator: 217, 224, 226;
    --dl-color-borders-separator-popup: 140, 140, 140;
    --dl-color-borders-separator-top-nav: 217, 224, 226;
    --dl-color-borders-toggle: 184, 185, 186;
}

html * {
    font-family: "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pointer_events_none {
    pointer-events: none;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
li,
ul,
th,
td {
    color: rgb(var(--dl-color-text-black));
    letter-spacing: 0.5px;
    font-weight: var(--dl-fontweight-400);
}

button {
    color: rgb(var(--dl-color-white-always));
    border: none;
    cursor: pointer;
    display: inline-flex;
    line-height: 1;
    font-size: 12px;
    transition: background-color .2s cubic-bezier(.785, .135, .15, .86) 0s;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    justify-content: center;
    background-color: rgb(var(--dl-color-primary-100));
    text-decoration: none;
    font-weight: var(--dl-fontweight-400);
}

button:hover {
    background-color: rgb(var(--dl-color-primary-300));
}

input {
    cursor: auto;
    outline: none;
    border-width: 1px;
    border-radius: 6px;
    background-color: transparent;
}

textarea {
    cursor: auto;
    outline: none;
    border-width: 1px;
    border-radius: 6px;
    background-color: transparent;
}

ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    list-style-type: none;
    list-style-position: outside;
}

li {
    display: flex;
    flex-direction: row;
    width: 100%
}

input[type=checkbox] {
    -webkit-appearance: initial;
    -moz-appearance: initial;
    -o-appearance: initial;
    -ms-appearance: initial;
    appearance: initial;
    accent-color: rgb(var(--dl-color-primary-100));
    height: 13px;
    width: 13px;
    flex-shrink: 0;
    border-radius: 3px;
    border-color: rgba(var(--dl-color-black), 0.3);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.app_popup_wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(var(--dl-color-black-always), 0.64);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app_popup_wrapper_middle {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 16px;
}

.popper_wrapper {
    position: relative;
    display: flex;
}

.shrink {
    flex-shrink: 1;
}

table {
    border-spacing: 0px;
    border-collapse: separate;
}

td {
    padding: 0px;
}

::-webkit-scrollbar {
    background-color: transparent;
    height: 6px;
    width: 6px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: rgba(var(--dl-color-text-gray5), 0.6);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--dl-color-text-gray5), 0.7);
}

::-webkit-scrollbar-corner {
    background: transparent;
}