.container {
    display: flex;
    padding: 16px 8px 16px 16px;
    align-items: flex-start;
    justify-content: space-between;
}

.filter_name {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 12px;
    margin-top: 4px;
}

.range_slider {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.range_slider_input_value {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    border-color: rgb(var(--dl-color-backgrounds-hover3));
    border-width: 1px;
    border-radius: 6px;
    justify-content: center;
    font-size: 13px;
    text-align: center;
}

.range_slider_input_value:focus {
    border-color: rgb(var(--dl-color-primary-100));
}

.range_slider_input_value:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.range_slider_input_value::placeholder {
    color: rgba(var(--dl-color-text-black), 0.7)
}

.range_slider_wrapper {
    width: 200px;
    height: 6px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: space-between;
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    position: relative;
}

.range_slider_colored {
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 4px;
    justify-content: space-between;
    background-color: rgb(var(--dl-color-primary-100));
    position: absolute;
}

.range_slider_toggle_wrapper {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
}

.range_slider_toggle {
    position: absolute;
    height: 100%;
    width: 100%;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    pointer-events: none;
}

.range_slider_toggle_wrapper input[type="range"]::-webkit-slider-thumb {
    width: 14px;
    height: 14px;
    box-shadow: 0px 1px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border: 2px solid rgb(var(--dl-color-borders-toggle));
    border-radius: 50%;
    background-color: rgb(var(--dl-color-primary-100));
    appearance: none;
    -webkit-appearance: none;
    pointer-events: auto !important;
}

.range_slider_toggle_wrapper input[type="range"]::-moz-range-thumb {
    width: 14px;
    height: 14px;
    box-shadow: 0px 1px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border: 2px solid rgb(var(--dl-color-borders-toggle));
    border-radius: 50%;
    background-color: rgb(var(--dl-color-primary-100));
    appearance: none;
    -moz-appearance: none;
    pointer-events: auto !important;
}

.delete_wrapper {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(var(--dl-color-text-black), 0.6);
    cursor: pointer;
    transition: 0.2s;
    border-radius: 4px;
}

.delete_wrapper:hover {
    color: rgb(var(--dl-color-colors-red));
}

.delete_icon {
    width: 14px;
    height: 14px;
    fill: currentColor;
    transform: rotateY(180deg);
}