.row_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator));
    position: relative;
}

.row_item_left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    width: 25%;
    max-width: 110px;
    flex-shrink: 0;
}

.row_item_icon_logo_wrapper {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    color: rgba(var(--dl-color-text-black), 0.8);
}

.row_item_icon_logo {
    height: 15px;
    fill: currentColor;
}

.row_item_ticker {
    font-size: 13px;
    letter-spacing: 0.5px;
}

.row_item_right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    flex-shrink: 1;
    width: calc(100% - 70px);
}

.row_item_text {
    font-size: 13px;
    letter-spacing: 0.5px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
    width: 33%;
}