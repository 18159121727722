.container {
    gap: 8px;
    margin: 8px 16px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}

.group_container {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dropdown_button {
    gap: 4px;
    color: rgb(var(--dl-color-text-black));
    border: 1px solid rgb(var(--dl-color-borders-separator-popup));
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    border-radius: 6px;
    padding-right: 8px;
    justify-content: space-between;
    cursor: pointer;
    flex-shrink: 1;
}

.dropdown_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.dropdown_button_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.dropdown_button_icon {
    fill: rgba(var(--dl-color-text-black), 0.8);
    width: 15px;
    height: 15px;
    transition: transform ease .2s;
}

.dropdown_button_text {
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-top: 1px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.button_icon {
    fill: rgba(var(--dl-color-text-black), 0.8);
    width: 16px;
    height: 16px;
}

.button {
    color: rgb(var(--dl-color-text-black));;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    border-color: rgb(var(--dl-color-borders-separator-popup));
    border-width: 1px;
    border-radius: 6px;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
}

.button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.antd_picker_calendar_icon {
    position: absolute;
    left: 8px;
    top: 5px;
    padding: 2px;
    width: 19px;
    height: 19px;
    fill: rgba(var(--dl-color-text-black), 0.8);
    z-index: 1;
}

.separator {
    height: 22px;
    background-color: rgba(var(--dl-color-text-black), 0.8);
    width: 1px;
    margin: 0px 8px;
}

.icon_priority {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.icon_priority_bar_1 {
    width: 4px;
    height: 9px;
    border: 1px solid rgba(var(--dl-color-text-black), 0.7);
    border-radius: 1px;
}

.icon_priority_bar_2 {
    width: 4px;
    height: 12px;
    border: 1px solid rgba(var(--dl-color-text-black), 0.7);
    border-radius: 1px;
}

.icon_priority_bar_3 {
    width: 4px;
    height: 15px;
    border: 1px solid rgba(var(--dl-color-text-black), 0.7);
    border-radius: 1px;
}

.search_row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0px 8px;
}

.ticker_search_dummy {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: visible;
    z-index: 1;
}

.ticker_search {
    display: flex;
    overflow: hidden;
    align-items: center;
    padding: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 30px;
    border-radius: 4px;
    justify-content: center;
    border: 1px solid rgb(var(--dl-color-borders-separator-popup));
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: max-width ease .2s;
}

.ticker_search_dummy:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.ticker_search_expanded {
    padding: 0px 8px;
    position: absolute;
    gap: 4px;
    cursor: default;
    max-width: 1000px;
    background-color: rgb(var(--dl-color-backgrounds-page));
}

.ticker_search_icon {
    fill: rgb(var(--dl-color-text-gray4));
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.ticker_search_input {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.3px;
    border: none;
    border-radius: 0px;
    height: 16px;
    overflow: hidden;
    line-height: 1;
    flex-shrink: 1;
}

.ticker_search_input::placeholder {
    color: rgba(var(--dl-color-text-gray4), 0.63);
}

.ticker_search_delete {
    height: 18px;
    width: 18px;
    color: rgb(var(--dl-color-text-black));
    border-radius: 4px;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.ticker_search_delete:hover {
    color: rgb(var(--dl-color-black));
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}
  
.ticker_search_delete_icon {
    height: 16px;
    width: 16px;
    fill: currentColor;
}

.popup_wrapper {
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border-radius: 6px;
    padding: 8px 2px 8px 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 100;
    width: 260px;
}

.popup_wrapper_scroll {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-y: scroll;
    gap: 4px;
}

.popup_search_wrapper {
	gap: 4px;
	display: flex;
	position: relative;
	align-items: center;
	padding-left: 20px;
	padding-right: 24px;
	justify-content: flex-start;
	height: 20px;
	margin: 0px 4px 4px;
}

.popup_search_icon {
	fill: rgb(var(--dl-color-text-gray4));
	left: 0px;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 2x;
}

.popup_search_input {
	width: 100%;
	display: flex;
	align-items: flex-start;
	font-size: 12px;
	font-weight: var(--dl-fontweight-300);
	letter-spacing: 0.3px;
	border: none;
	border-radius: 0px;
	height: 16px;
}

.popup_search_input::placeholder {
	color: rgba(var(--dl-color-text-gray4), 0.63);
}

.popup_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 8px;
    border-radius: 6px;
    height: 34px;
    white-space: nowrap;
    flex-wrap: nowrap;
    cursor: default;
    gap: 8px;
    flex-shrink: 0;
}

.popup_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.popup_item_icon_wrapper {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_item_checkbox {
	width: 10px;
	height: 10px;
	border: 1px solid rgba(var(--dl-color-text-gray1), 0.5);
	border-radius: 2px;
	display: flex;
	cursor: default;
    flex-shrink: 0;
}

.popup_item_checkbox_inner {
	width: 100%;
	height: 100%;
	border: 1px solid rgb(var(--dl-color-backgrounds-white-on-white));
	border-radius: 2px;
}

.popup_item_text {
    overflow: hidden;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.popup_middle_container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    left: 0 !important;
    top: 0 !important;
    background-color: rgba(var(--dl-color-black-always), 0.64);
    overflow: hidden;
}

.popup_middle_top_row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 16px 0px;
    height: 54px;
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator-popup));
}

.popup_middle_top_row_title {
    letter-spacing: 0.5px;
    font-size: 20px;
    font-weight: var(--dl-fontweight-500);
}

.popup_middle_top_row_icon_x_wrapper {
    height: 24px;
    width: 24px;
    color: rgb(var(--dl-color-text-black));
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
}

.popup_middle_top_row_icon_x_wrapper:hover {
    color: rgb(var(--dl-color-black));
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.popup_middle_top_row_icon_x {
    height: 22px;
    width: 22px;
    fill: currentColor;
}

.popup_middle_middle_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 16px;
}

.popup_middle_search_wrapper {
	gap: 6px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 8px;
    height: 36px;
    flex-grow: 1;
    max-width: 260px;
    border-radius: 6px;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover3));
}

.popup_middle_search_icon {
	fill: rgb(var(--dl-color-text-gray4));
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}

.popup_middle_search_input {
	display: flex;
    align-items: flex-start;
    font-size: 13px;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.3px;
    border: none;
    border-radius: 0px;
    height: 18px;
    overflow: hidden;
    line-height: 1;
    flex: 1 1;
}

.popup_middle_search_input::placeholder {
	color: rgba(var(--dl-color-text-gray4), 0.63);
}

.popup_middle_search_delete {
	height: 22px;
    width: 22px;
    color: rgba(var(--dl-color-text-black), 0.6);
    border-radius: 4px;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_middle_search_delete:hover {
	color: rgba(var(--dl-color-text-black), 0.8);
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.popup_middle_search_delete_icon {
	width: 18px;
	height: 18px;
    fill: currentColor;
}

.popup_middle_middle_button_row {
    padding: 8px 16px;
    border-bottom: 1px solid rgb(var(--dl-color-backgrounds-hover3));
}

.template_row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
}

.popup_tab_button {
    padding: 0px 8px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}

.popup_tab_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.popup_tab_button_active {
    background-color: rgb(var(--dl-color-backgrounds-hover3)) !important;
    pointer-events: none;
}

.popup_tab_button_text {
    font-size: 13px;
}

.popup_dropdown_button {
    gap: 8px;
    width: auto;
    min-width: 80px;
    height: 32px;
    display: flex;
    padding: 0px 8px;
    transition: 0.3s;
    align-items: center;
    border-color: rgb(var(--dl-color-backgrounds-hover3));
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    justify-content: space-between;
    cursor: pointer;
    max-width: 260px;
    transition: ease .2s;
}

.popup_dropdown_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.popup_dropdown_button_text {
    font-size: 13px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.popup_dropdown_button_icon {
    fill: rgba(var(--dl-color-text-black), 0.8);
    width: 15px;
    height: 15px;
    transition: transform ease .2s;
    flex-shrink: 0;
}

.source_count_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--dl-color-primary-300));
    border-radius: 30px;
    padding: 0px 4px;
    height: 20px;
    min-width: 20px;
}

.source_count {
    font-size: 13px;
    line-height: 1;
    color: rgb(var(--dl-color-primary-100));
}

.popup_middle_selected_row {
    display: flex;
    padding: 0px 16px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;
}

.popup_middle_selected_item {
    height: 28px;
    border-radius: 6px;
    padding: 0px 4px;
    color: rgb(var(--dl-color-white-always));
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.popup_middle_selected_item_wrapper {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
}

.popup_middle_selected_item_logo {
    height: 18px;
}

.popup_middle_selected_item_text {
    color: rgb(var(--dl-color-black));
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    line-height: 1;
    margin-left: 2px;
}

.popup_middle_selected_item_icon_x_wrapper {
    height: 20px;
    width: 20px;
    color: rgb(var(--dl-color-text-black));
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_middle_selected_item_icon_x_wrapper:hover {
    color: rgb(var(--dl-color-black));
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.popup_middle_selected_item_icon_x {
    height: 16px;
    width: 16px;
    fill: currentColor;
}

.popup_middle_content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 16px 2px 8px 8px;
    width: 100vw;
    height: 100vh;
}

.popup_middle_content_column_split {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 8px;
    height: 100%;
}

.popup_middle_wrapper_scroll {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-y: scroll;
    position: relative;
    gap: 8px;
    flex-grow: 1;
}

.popup_middle_content_column_separator {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
    width: 1px;
    flex-shrink: 0;
}

.popup_middle_category {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.popup_middle_category_title {
    color: rgb(var(--dl-color-text-gray4));
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: var(--dl-fontweight-500);
    margin: 4px 8px;
}

.popup_middle_category_content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;
}

.popup_middle_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 8px;
    border-radius: 6px;
    height: 40px;
    white-space: nowrap;
    flex-wrap: nowrap;
    cursor: default;
    gap: 8px;
    width: calc(50% - 2px);
}

.popup_middle_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.popup_middle_item_logo_wrapper {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
}

.popup_middle_item_logo {
    height: 22px;
}

.popup_middle_settings_row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 16px;
    gap: 16px;
}

.popup_middle_settings_row_title {
    margin-top: 4px;
    color: rgb(var(--dl-color-text-gray3));
    font-size: 12px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 120px;
}

.popup_middle_settings_row_right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 2px;
}

.popup_middle_settings_row_subtext {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 11px;
	max-width: 250px;
    margin-top: 4px;
}

.popup_middle_bottom_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-top: 1px solid rgb(var(--dl-color-borders-separator-popup));
}

.popup_middle_bottom_row_split {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.popup_middle_bottom_row_button {
    height: 30px;
    padding: 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover3));
    border-radius: 6px;
    cursor: pointer;
}

.popup_middle_bottom_row_button:hover {
    color: rgb(var(--dl-color-black));
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.popup_middle_bottom_row_button_blue {
    height: 30px;
    padding: 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(var(--dl-color-primary-100));
    background-color: rgb(var(--dl-color-primary-100));
    border-radius: 6px;
    color: rgb(var(--dl-color-white-always));
    cursor: pointer;
}

.popup_middle_bottom_row_button_blue:hover {
    background-color: rgb(var(--dl-color-primary-300));
    border-color: rgb(var(--dl-color-primary-300));
}

.popup_middle_bottom_row_button_text {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: currentColor;
}

.popup_middle_bottom_row_matches {
    color: rgba(var(--dl-color-text-black), 0.6);
    font-size: 13px;
    text-align: center;
}