.reset_time_date_picker {
    display: flex;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    height: 42px;
    width: 60%;
    overflow: hidden;
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
    flex-shrink: 0;
}

.reset_time_date_picker .ant-picker {
    border: none !important;
    padding-left: 16px !important;
    border-radius: 0px !important;
}

.reset_time_date_picker .ant-picker-input>input::placeholder {
    color: rgba(var(--dl-color-text-gray2), 0.85) !important;
    font-weight: var(--dl-fontweight-300) !important;
}

.reset_time_date_picker .ant-picker-suffix {
    display: none !important
}

.reset_time_picker {
    display: flex;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    height: 42px;
    overflow: visible;
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
    flex-shrink: 1;
}

.reset_time_picker .ant-picker {
    border: none !important;
    padding-left: 16px !important;
    border-radius: 0px !important;
}

.reset_time_picker .ant-picker-input>input::placeholder {
    color: rgba(var(--dl-color-text-gray2), 0.85) !important;
    font-weight: var(--dl-fontweight-300) !important;
}

.reset_time_picker .ant-picker-suffix {
    display: none !important
}