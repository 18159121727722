.wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(var(--dl-color-black-always), 0.64);
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    background-color: rgb(var(--dl-color-backgrounds-white));
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin: 16px;
}

.top_bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 16px 0px;
    height: 54px;
    flex-shrink: 0;
}

.header {
    letter-spacing: 0.5px;
    font-size: 20px;
    font-weight: var(--dl-fontweight-500);
}

.icon_x_wrapper {
    height: 24px;
    width: 24px;
    color: rgb(var(--dl-color-text-black));
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
}

.icon_x_wrapper:hover {
    color: rgb(var(--dl-color-black));
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.icon_x {
    height: 22px;
    width: 22px;
    fill: currentColor;
}

.header_buttons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.header_cancel_button {
    height: 30px;
    border-color: rgb(var(--dl-color-primary-100));
    border-width: 1px;
    padding: 8px 10px;
    border-radius: 4px;
    color: rgb(var(--dl-color-primary-100));
    cursor: pointer;
    line-height: 1.1;
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_cancel_button:hover {
    background-color: rgba(var(--dl-color-primary-100), 0.09);
}

.header_save_button {
    height: 30px;
    border-color: rgb(var(--dl-color-primary-100));
    background-color: rgb(var(--dl-color-primary-100));
    border-width: 1px;
    padding: 8px 10px;
    border-radius: 4px;
    color: rgb(var(--dl-color-white-always));
    cursor: pointer;
    line-height: 1.1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.header_save_button:hover {
    background-color: rgb(var(--dl-color-primary-300));
    border-color: rgb(var(--dl-color-primary-300));
}

.header_save_button_icon {
    fill: currentColor;
    width: 15px;
    height: 15px;
}

.header_save_button_icon_text {
    color: currentColor;
    font-size: 11px;
    letter-spacing: 0.5px;
}

.content_wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    border-top: 1px solid rgb(var(--dl-color-borders-separator));
}

/*SIDEBAR*/
.sidebar {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 16px 0px;
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
}

.sidebar_top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.sidebar_top_category {
    display: flex;
    height: 40px;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 16px;
}

.sidebar_top_category_text {
    text-transform: uppercase;
    font-size: 12px;
    color: rgba(var(--dl-color-text-black), 0.6);
    letter-spacing: 0.3px;
}

.sidebar_top_item {
    color: rgb(var(--dl-color-text-gray2));
    padding: 0px 16px;
    height: 40px;
    gap: 12px;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.sidebar_top_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.sidebar_top_sidebar {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -3px;
    width: 6px;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.sidebar_top_sidebar_icon_wrapper {
    color: rgba(var(--dl-color-black), 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
}

.sidebar_top_item_icon {
    width: 15px;
    height: 15px;
    fill: currentColor;
}

.sidebar_top_item_text {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: rgb(var(--dl-color-text-black));
}

.separator {
    background-color: rgb(var(--dl-color-borders-separator));
    height: 1px;
    margin: 8px 0px;
}

.sidebar_bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.sidebar_bottom_item {
    gap: 8px;
    color: rgb(var(--dl-color-text-gray2));
    height: 36px;
    display: flex;
    padding: 0px 16px;
    align-items: center;
    border-radius: 6px;
    justify-content: flex-start;
    cursor: pointer;
}

.sidebar_bottom_item:hover {
    color: rgb(var(--dl-color-primary-100));
}

.sidebar_bottom_item_text {
    color: currentcolor;
    font-size: 13px;
    letter-spacing: 0.3px;
}

.sidebar_bottom_item_icon {
    fill: currentColor;
    width: 12px;
    height: 12px;
}

.content_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-grow: 1;
}

/*CARD*/
.card_container {
    gap: 12px;
    width: 300px;
    height: 180px;
    display: flex;
    padding: 8px 16px;
    position: relative;
    box-shadow: 0px 1px 3px 0px rgba(var(--dl-color-shadow), 0.2);
    transition: 0.3s;
    align-items: stretch;
    border-color: rgb(var(--dl-color-borders-separator));
    border-width: 1px;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-white));
    cursor: pointer;
}

.card_container:hover {
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    background-color: rgba(var(--dl-color-backgrounds-hover2), 0.2);
}

.card_icons {
    gap: 8px;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.card_text_wrapper {
    gap: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.card_description_title {
    color: rgb(var(--dl-color-text-black));
    font-size: 16px;
    font-weight: var(--dl-fontweight-500);
    letter-spacing: 0.5px;
}

.card_description_description {
    color: rgba(var(--dl-color-text-gray1), 0.72);
    padding: 0px 8px;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.2px;
}