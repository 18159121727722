.container_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 300px;
}

.navlink {
    display: contents;
}

.logo_container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-logo));
    border-radius: 50%;
}

.logo_container:hover {
    opacity: 0.9;
}

.logo_image {
    width: 26px;
    height: 26px;
    object-fit: cover;
    border-radius: 50%;
}