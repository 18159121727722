.wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 16px 0px;
    height: 100%;
    flex-shrink: 1;
}

.explanation_text_row {
    gap: 4px;
    display: flex;
    padding: 0px 24px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    margin-bottom: 8px;
}

.explanation_text {
    font-size: 13px;
}

.explanation_text_help_icon {
    fill: rgba(var(--dl-color-text-black), 0.57);
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-bottom: 1px;
}

.explanation_text_help_icon:hover {
    fill: rgba(var(--dl-color-text-black), 0.8)
}

.tab_selection_wrapper {
    gap: 4px;
    display: flex;
    padding: 0px 24px;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    margin-bottom: 2px;
}

.tab_row {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.tab_row_tabs {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tab_row_tab {
    height: 30px;
    display: flex;
    padding: 0px 16px;
    align-items: center;
    border-radius: 30px;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    cursor: pointer;
}

.tab_row_tab:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.tab_row_tab_active {
    background-color: rgb(var(--dl-color-backgrounds-gray-dark));
    color: rgb(var(--dl-color-white));
}

.tab_row_tab_active:hover {
    background-color: rgb(var(--dl-color-backgrounds-gray-dark));
    color: rgb(var(--dl-color-white));
}

.tab_row_tab_text {
    font-size: 14px;
    font-weight: var(--dl-fontweight-500);
    color: currentColor;
}

.search_wrapper {
	gap: 6px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 8px;
    height: 36px;
    flex-grow: 1;
    max-width: 260px;
    border-radius: 6px;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover3));
}

.search_icon {
	fill: rgb(var(--dl-color-text-gray4));
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}

.search_input {
	display: flex;
    align-items: flex-start;
    font-size: 12px;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.3px;
    border: none;
    border-radius: 0px;
    height: 18px;
    overflow: hidden;
    line-height: 1;
    flex: 1 1;
}

.search_input::placeholder {
	color: rgba(var(--dl-color-text-gray4), 0.63);
}

.search_delete {
	height: 22px;
    width: 22px;
    color: rgba(var(--dl-color-text-black), 0.6);
    border-radius: 4px;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search_delete:hover {
	color: rgba(var(--dl-color-text-black), 0.8);
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.search_delete_icon {
	width: 18px;
	height: 18px;
    fill: currentColor;
}

.dropdown_wrapper {
    display: flex;
    position: relative;
    align-items: center;
    padding: 0px 24px;
}

.stocklist_wrapper {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 16px;
}

.dropdown_button {
    gap: 2px;
    height: 30px;
    display: flex;
    padding: 4px 12px 4px 8px;
    align-items: center;
    border-radius: 30px;
    justify-content: flex-start;
    cursor: pointer;
    flex-shrink: 0;
}

.dropdown_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.dropdown_button_arrow_icon {
    fill: rgb(var(--dl-color-text-black));
    width: 18px;
    height: 18px;
}

.dropdown_button_text {
    font-size: 12px;
}

.stocklist_list {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    flex-grow: 1;
}

.stocklist_list_scroll {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    height: 100%;
}

.stocklist_list_scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.stocklist_row {
    display: flex;
    padding: 8px 24px;
    align-items: center;
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator));
    justify-content: flex-start;
    cursor: default;
    transition: height ease .2s;
    overflow: hidden;
    position: relative;
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
    flex-shrink: 0;
}

.stocklist_row_absolute {
    top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.stocklist_row:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.stocklist_row_left {
    gap: 8px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.stocklist_row_logo_wrapper {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-skeleton-dark));
    position: relative;
}

.stocklist_row_logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.filter_alert_icon_wrapper {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter_icon {
    width: 17px;
    height: 17px;
    fill: rgb(var(--dl-color-text-black));
}

.alert_icon {
    width: 20px;
    height: 20px;
    margin-bottom: 1px;
    fill: rgb(var(--dl-color-text-black));
}

.stocklist_row_text {
    font-size: 13px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.stocklist_row_middle {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.symbol_count {
    gap: 4px;
    display: flex;
    align-items: center;
}

.symbol_count_text_gray {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 11px;
    margin-top: 2px;
}

.stocklist_state_list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.stocklist_row_arrow_icon {
    fill: rgb(var(--dl-color-text-black));
    width: 18px;
    height: 18px;
    transition: ease .2s;
    flex-shrink: 0;
    margin-right: 6px;
}

.selected_sources_wrapper {
    gap: 16px;
    display: flex;
    padding: 0px 24px;
    margin-top: 16px;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
}

.selected_sources_title {
    font-size: 13px;
}

.selected_sources_content {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 30px;
}

.selected_sources_list {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.selected_sources_list_item {
    gap: 4px;
    height: 30px;
    display: flex;
    padding: 4px 8px;
    align-items: center;
    border-radius: 6px;
    justify-content: flex-start;
    background-color: rgba(var(--dl-color-colors-green-dark), 0.18);
    cursor: default;
}

.selected_sources_list_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.selected_sources_list_item_logo_wrapper {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-skeleton-dark));
    position: relative;
}

.selected_sources_list_item_logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.selected_sources_list_item_text {
    font-size: 12px;
    line-height: 1;
    margin-left: 4px;
}

.selected_sources_list_item_delete_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 18px;
    height: 18px;
}

.selected_sources_list_item_delete_icon_wrapper:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.selected_sources_list_item_delete_icon {
    fill: rgb(var(--dl-color-text-black));
    width: 16px;
    height: 16px;
}