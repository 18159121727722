.container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: rgb(var(--dl-color-backgrounds-page-back));
    border-top-left-radius: 6px;
    overflow: hidden;
}

.builder_wrapper {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    border-bottom-left-radius: 6px;
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
}

.builder {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    position: absolute;
    inset: 0;
}

.top_bar {
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
    height: 38px;
    flex-shrink: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.top_bar_tabs {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-shrink: 1;
    overflow-x: auto;
}

.top_bar_tabs::-webkit-scrollbar {
    height: 2px;
    width: 2px;
}

.top_bar_tab {
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
    gap: 8px;
    cursor: pointer;
}

.top_bar_tab:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover-light));
}

.top_bar_tab_logo_wrapper {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-skeleton-dark));
    position: relative;
}

.top_bar_tab_logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.top_bar_filter_alert_icon_wrapper {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top_bar_filter_icon {
    width: 17px;
    height: 17px;
    fill: rgb(var(--dl-color-text-black));
}

.top_bar_alert_icon {
    width: 20px;
    height: 20px;
    margin-bottom: 1px;
    fill: rgb(var(--dl-color-text-black));
}

.top_bar_tab_name {
    overflow: hidden;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.screener_input_list_count_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    border-radius: 30px;
    padding: 0px 4px;
    height: 24px;
    min-width: 24px;
}

.screener_input_list_count {
    font-size: 12px;
    line-height: 1;
}

.tab_drag_line {
    width: 2px;
    background-color: rgb(var(--dl-color-primary-100));
}

.top_bar_plus_icon_wrapper {
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 0px 6px;
}

.top_bar_plus_icon_wrapper:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover-light));
}

.top_bar_plus_icon_wrapper_active {
    cursor: default;
    background-color: rgb(var(--dl-color-backgrounds-hover2)) !important;
}

.top_bar_plus_icon {
    width: 24px;
    height: 24px;
    fill: rgb(var(--dl-color-text-black));
}

.top_bar_add_state_text {
    font-size: 13px;
}

.top_bar_right {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    width: 340px;
}

.top_bar_right_icon_wrapper {
    border-left: 1px solid rgb(var(--dl-color-borders-separator));
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0px 16px;
}

.top_bar_right_icon_wrapper:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover-light));
}

.top_bar_right_stop_button {
    border-left: 1px solid rgb(var(--dl-color-borders-separator));
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 0px 16px 0px 14px;
    background-color: rgb(var(--dl-color-colors-green));
    flex-grow: 1;
}

.top_bar_right_stop_button:hover {
    background-color: rgb(var(--dl-color-colors-green-hover));
}

.top_bar_right_start_button {
    background-color: rgb(var(--dl-color-colors-red-pink));
}

.top_bar_right_start_button:hover {
    background-color: rgb(var(--dl-color-colors-red-pink-hover));
}

.top_bar_right_icon {
    width: 24px;
    height: 24px;
    fill: rgb(var(--dl-color-text-black));
    transition: ease .2s;
}

.top_bar_right_icon_text {
    font-size: 13px;
}

.conditions_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-grow: 1;
    overflow-y: auto;
}

.conditions_container::-webkit-scrollbar {
    display: none;
}

.add_state_wrapper {
    margin: 16px;
    flex: 1 1 auto;
    overflow: scroll;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 16px;
}

.add_state_card {
    width: 250px;
    height: 300px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: stretch;
    border: 1px solid rgb(var(--dl-color-borders-separator));
    border-radius: 8px;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
}

.add_state_card:hover {
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
}

.add_state_card_top {
    gap: 8px;
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.add_state_card_title {
    font-size: 15px;
    font-weight: var(--dl-fontweight-500);
}

.add_state_card_description_wrapper {
    display: flex;
    padding: 16px;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
}

.add_state_card_description {
    font-size: 13px;
}  

.settings_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 340px;
}

.settings_content {
    padding: 16px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;
    flex-direction: column;
    overflow-y: auto;
}

.settings_content::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.settings_content_row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    padding: 0px 16px;
}

.settings_row_left {
    width: 120px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    flex-shrink: 0;
}

.settings_help_icon {
	width: 12px;
	height: 12px;
	cursor: pointer;
	fill: rgba(var(--dl-color-text-black), 0.57);
    flex-shrink: 0;
    margin-top: 6px;
}

.settings_help_icon:hover {
	fill: rgba(var(--dl-color-text-black), 0.8)
}

.settings_row_title {
    margin-top: 4px;
    color: rgb(var(--dl-color-text-gray3));
    font-size: 12px;
	max-width: 120px;
}

.settings_row_right {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 2px;
    flex-shrink: 1;
    width: 100%;
}

.name_input {
    height: 36px;
    border-color: rgb(var(--dl-color-borders-separator));
    min-width: 230px;
    border-radius: 6px;
    padding: 0px 8px;
    text-align: start;
    font-size: 13px;
}

.name_input:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.name_input::placeholder {
    color: rgb(var(--dl-color-text-black), 0.57)
}

.name_input:focus {
    border-color: rgb(var(--dl-color-primary-100));
}

.number_input_wrapper {
    position: relative;
    align-self: flex-start;
}

.number_input {
    height: 36px;
    border-color: rgb(var(--dl-color-borders-separator));
    width: 80px;
    border-radius: 6px;
    padding: 0px 20px 0px 12px;
    text-align: start;
    font-size: 13px;
}

.number_input_wrapper:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.number_input::placeholder {
    color: rgb(var(--dl-color-text-black), 0.57)
}

.number_input:focus {
    border-color: rgb(var(--dl-color-primary-100));
}

.number_input_arrows {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 4px;
    height: 100%;
}

.number_input_arrow_wrapper {
    width: 16px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    flex-shrink: 0;
    color: rgba(var(--dl-color-text-black), 0.46);
}

.number_input_arrow_wrapper:hover {
    color: rgb(var(--dl-color-text-black));
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.number_input_arrows_icon {
    fill: currentColor;
    width: 16px;
    height: 16px;   
}

.settings_row_subtext {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 11px;
    margin-top: 4px;
}

.toggle_switch_big_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border: 1px solid rgb(var(--dl-color-borders-separator));
    border-radius: 6px;
    flex-grow: 1;
}

.toggle_switch_big {
    border: 1px solid rgb(var(--dl-color-borders-separator));
    padding: 0px 8px;
    border-radius: 6px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    cursor: default;
    gap: 4px;
    flex-grow: 1;
}

.toggle_switch_big_multi {
    border: 1px solid rgb(var(--dl-color-borders-separator));
    padding: 0px 8px;
    border-radius: 6px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    cursor: default;
    gap: 4px;
    flex-grow: 1;
}

.toggle_switch_big_separator {
    height: 1px;
    background-color: rgb(var(--dl-color-borders-separator));
}

.toggle_big_background {
    border-radius: 6px;
    position: absolute;
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    top: 4px;
    bottom: 4px;
    right: calc(50% + 4px);
    left: 8px;
    transition: ease .2s;
}

.toggle_big_background_right {
    left: calc(50% + 4px);
    right: 8px;
}

.toggle_big_background_toggled {
    left: calc(50% + 8px);
    right: 8px;
    background-color: rgb(var(--dl-color-primary-100))
}

.toggle_big_background_multi {
    border-radius: 6px;
    position: absolute;
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    top: 4px;
    bottom: 4px;
    left: 8px;
    width: 25%;
    min-width: 28px;
    transition: ease .2s;
}

.toggle_big_item_multi {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    flex: 1 1 auto;
    min-width: 28px;
    z-index: 1;
    cursor: pointer;
    margin: 4px 0px;
}

.toggle_big_item_multi:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.toggle_big_item_multi_text {
    font-size: 13px;
    line-height: 1;
}

.toggle_big_text {
    font-size: 13px;
    line-height: 1;
    width: 46%;
    text-align: center;
    z-index: 1;
}

.frequency_picker_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.frequency_picker {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.interval_picker_container {
    display: flex;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    flex-shrink: 1;
    overflow: visible;
    align-self: center;
}

.delete_icon {
    fill: rgba(var(--dl-color-text-black), 0.8);
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.delete_icon:hover {
    fill: rgba(var(--dl-color-colors-red), 0.8);
    width: 16px;
    height: 16px;
}

.interval_picker_button {
    width: 100%;
    height: 28px;
    display: flex;
    padding: 0px 8px;
    position: relative;
    transition: 0.3s;
    align-items: center;
    border-color: rgb(var(--dl-color-borders-separator));
    border-width: 1px;
    border-radius: 6px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-width: 1px;
    flex-shrink: 0;
    cursor: pointer;
}

.interval_picker_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.interval_picker_button_left {
    gap: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.interval_picker_button_icon {
    fill: rgba(var(--dl-color-text-black), 0.46);
    width: 14px;
    height: 14px;
}

.interval_picker_button_text_wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.interval_picker_button_text_small {
    color: rgb(var(--dl-color-text-gray2));
    font-size: 9px;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.3px;
    margin-bottom: 3px;
}

.interval_picker_button_text {
    color: rgb(var(--dl-color-text-black));
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 1;
}

.interval_picker_button_text_placeholder {
    color: rgba(var(--dl-color-text-gray2), 0.85);
    font-size: 13px;
    letter-spacing: 0.3px;
    font-weight: var(--dl-fontweight-300);
}

.interval_picker_button_right {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.interval_picker_button_arrow_icon {
    fill: rgba(var(--dl-color-text-black), 0.46);
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    transition: ease .2s;
}

.interval_picker_button_options_wrapper {
    padding: 4px;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border-radius: 6px;
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    width: 100%;
    z-index: 10;
}

.interval_picker_button_option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
}

.interval_picker_button_option:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.interval_picker_button_option_text {
    color: currentColor;
    font-size: 13px;
    letter-spacing: 0.3px;
}

.up_down_icons {
    position: absolute;
    right: 4px;
    top: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.up_down_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px;
    overflow: hidden;
    border-radius: 2px;
    width: 16px;
}

.up_down_icon_wrapper:hover {
    background-color: rgba(var(--dl-color-text-black), 0.05);
}

.up_down_icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    fill: rgba(var(--dl-color-black), 0.4);
}

.reset_time_wrapper {
    border: 1px solid rgb(var(--dl-color-borders-separator));
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-grow: 1;
}

.toggle_big_counter_blue {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgb(var(--dl-color-primary-100));
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
}

.toggle_big_counter_blue_number {
    font-size: 12px;
    color: rgb(var(--dl-color-white-always));
}

.reset_time_table {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.reset_time_table_header {
    display: flex;
    height: 24px;
    align-items: stretch;
    justify-content: flex-start;
}

.reset_time_table_header_date {
    width: 60%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 16px;
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
}

.reset_time_table_header_time {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 16px;
    gap: 4px;
}

.reset_time_table_header_text {
    font-size: 11px;
    letter-spacing: 0.3px;
    color: rgb(var(--dl-color-text-gray3));
    line-height: 1;
    margin-top: 1px;
}

.reset_time_table_body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.title_row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 16px 0px;
    height: 54px;
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator));
    flex-shrink: 0;
    border-top: 1px solid rgb(var(--dl-color-borders-separator-popup));
}

.title_text {
    font-size: 20px;
}

.conditions_criteria_wrapper {
    margin: 16px;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    min-height: 100px;
}

.conditions_bottom_row {
    padding: 16px;
    height: 62px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    flex-shrink: 0;
    border-top: 1px solid rgb(var(--dl-color-borders-separator));
}

.conditions_delete_state_button {
    color: rgb(var(--dl-color-colors-red));
    padding: 8px;
    font-size: 14px;
    border-radius: 6px;
    line-height: 1;
    border: 1px solid rgb(var(--dl-color-colors-red));
    transition: none;
    box-shadow: none;
    background-color: transparent;
    white-space: nowrap;
}

.conditions_delete_state_button:hover {
    background-color: rgb(var(--dl-color-colors-red));
    color: rgb(var(--dl-color-white-always));
}

.conditions_cancel_button {
    padding: 8px;
    font-size: 14px;
    border-radius: 6px;
    line-height: 1;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover3));
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
    transition: none;
    color: rgb(var(--dl-color-text-black));
}

.conditions_cancel_button:hover {
    color: rgb(var(--dl-color-black));
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.conditions_add_state_button {
    padding: 8px;
    font-size: 14px;
    border-radius: 6px;
    height: 30px;
    line-height: 1;
    border: 1px solid rgb(var(--dl-color-primary-100));
    transition: none;
}

.conditions_add_state_button:hover {
    border: 1px solid rgb(var(--dl-color-primary-300));
    background-color: rgb(var(--dl-color-primary-300));
}

.conditions_add_state_button:disabled {
    cursor: not-allowed;
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    border-color: rgb(var(--dl-color-backgrounds-hover2));
    color: rgba(var(--dl-color-text-black), 0.8);
}

.vertical_separator_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 5px;
    flex-shrink: 0;
    cursor: e-resize;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2px;
}

.vertical_separator {
    width: 1px;
    background-color: rgb(var(--dl-color-borders-separator-popup));
    flex-grow: 1;
}