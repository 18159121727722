.page_wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    overflow: hidden;
    background-color: rgb(var(--dl-color-backgrounds-page));
}

.page_container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
    background-color: rgb(var(--dl-color-backgrounds-page));
}

.content_container {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}

/*CHECKBOX*/
.checkbox_row {
    flex: 0 0 auto;
    color: rgb(var(--dl-color-text-black));
    width: 100%;
    height: auto;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: flex-start;
}

.checkbox {
    align-self: flex-start;
    margin-top: 2px;
    margin-right: 5px;
    flex-grow: 1;
    z-index: 2;
}

.checkbox_checked_background {
    background-color: rgb(var(--dl-color-primary-100));
    position: absolute;
    left: 2px;
    top: 4px;
    width: 9px;
    height: 9px;
    border-radius: 2px;
    z-index: 1;
}

.checkbox_label {
    align-self: flex-start;
    cursor: default;
    font-size: 10px;
}

.checkbox_link {
    text-decoration: underline;
}