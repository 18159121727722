.row_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator));
    position: relative;
}

.row_item_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    width: 33%;
    max-width: 70px;
    flex-shrink: 0;
}

.row_item_time {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 1;
}

.row_item_icons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
}

.row_item_icon_flag_wrapper {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    color: rgba(var(--dl-color-text-black), 0.8);
}

.row_item_icon_flag {
    height: 15px;
    fill: currentColor;
}

.row_item_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    flex-shrink: 1;
    width: calc(100% - 70px);
    gap: 2px;
}

.row_item_event_name {
    font-size: 13px;
    letter-spacing: 0.5px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
    max-width: 98%;
}

.row_item_right_bottom_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.row_item_right_bottom_row_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    color: rgb(var(--dl-color-text-gray3));
    width: 33%;
}

.row_item_right_bottom_row_item_text {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: currentColor;
}