.container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  align-items: stretch;
  flex-direction: column;
  backdrop-filter: blur(1px);
  justify-content: flex-start;
}

.container.height_100 {
  height: 100%;
}

.top_bar {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  padding: 0px 16px;
  z-index: 1;
  align-items: center;
  justify-content: flex-end;
  background-color: rgb(var(--dl-color-backgrounds-white));
}

.dashboard_link_container {
  width: auto;
  height: auto;
  margin-right: 16px;
}

.dashboard_link {
  font-size: 14px;
}

.top-nav-dashboard-link:hover {
  color: rgb(var(--dl-color-text-gray2));
}

.navlink_button {
  color: rgb(var(--dl-color-text-black));
  font-size: 14px;
  font-style: normal;
  transition: 0.1s;
  font-weight: var(--dl-fontweight-700);
  border-width: 0px;
  line-height: 1.55;
  text-decoration: none;
  background-color: rgb(var(--dl-color-backgrounds-page));
}

.navlink_button:hover {
  border: none;
  background-color: rgb(var(--dl-color-backgrounds-hover2));
  box-shadow: none;
}

.navlink_button_tryfree {
  font-size: 14px;
  box-shadow: 0px 9px 23px 0px rgba(var(--dl-color-primary), 0.5);
  font-style: normal;
  transition: 0.1s;
  font-weight: var(--dl-fontweight-700);
  line-height: 1.55;
  border-color: rgb(var(--dl-color-primary-100));
  border-width: 0px;
  text-decoration: none;
  background-color: rgb(var(--dl-color-primary-100));
  cursor: pointer;
}

.navlink_button_tryfree:hover {
  border: none;
  border-color: rgb(var(--dl-color-primary-300));
  background-color: rgb(var(--dl-color-primary-300));
}

.popup_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  background-image: radial-gradient(rgb(var(--dl-color-white)), rgba(var(--dl-color-white), 0.5));
}

.popup_container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  box-shadow: 0px 0px 100px 100px rgb(var(--dl-color-white));
  align-items: center;
  padding-top: 48px;
  padding-left: 32px;
  border-radius: 50%;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: 48px;
  justify-content: center;
  background-color: rgb(var(--dl-color-backgrounds-white));
}

.icon_x {
  top: 16px;
  right: 16px;
  width: 24px;
  cursor: pointer;
  height: 24px;
  opacity: 0.75;
  padding: 2px;
  position: absolute;
  align-self: flex-end;
  fill: rgb(var(--dl-color-text-black));
}

.signup_text {
  font-size: 36px;
  font-style: normal;
  font-weight: var(--dl-fontweight-800);
}

.signup_subtext {
  font-size: 18px;
  font-style: normal;
  font-weight: var(--dl-fontweight-600);
}

.email_input_container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  margin-top: 24px;
  align-items: stretch;
}

.icon_email {
  top: 15px;
  fill: rgb(var(--dl-color-text-gray2));
  left: 13px;
  width: 20px;
  height: 20px;
  position: absolute;
  align-self: center;
}

.email_input {
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  transition: 0.1s;
  padding-top: 16px;
  border-color: rgb(var(--dl-color-text-gray2));
  padding-left: 42px;
  padding-right: 21px;
  padding-bottom: 16px;
}

.email_input:focus {
  box-shadow: none;
  border-color: rgb(var(--dl-color-text-black));
  outline-width: none;
}

.email_input:hover {
  border-color: rgb(var(--dl-color-text-black));
}

.button_next_link {
  width: 100%;
}

.button_next {
  height: 50px;
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  box-shadow: 0px 9px 23px 0px rgba(var(--dl-color-primary), 0.5);
  font-style: normal;
  margin-top: 24px;
  text-align: center;
  transition: 0.1s;
  font-weight: var(--dl-fontweight-700);
  border-width: 0px;
  text-decoration: none;
}

.button_next:hover {
  background-color: rgb(var(--dl-color-primary-300));
}