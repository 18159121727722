.widget_top_row_antd_picker {
    display: flex;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    height: 30px;
    width: 180px;
    flex-shrink: 1;
}

.widget_top_row_antd_picker .ant-picker-input {
    height: 30px !important;
}

.widget_top_row_antd_picker .ant-picker {
    padding: 0px 0px 0px 34px;
}