.content_wrapper {
	gap: 16px;
	flex-grow: 1;
	padding: 24px;
	display: flex;
	position: relative;
	align-items: stretch;
	flex-direction: column;
	justify-content: flex-start;
	min-width: 800px;
	border-top: 1px solid rgb(var(--dl-color-borders-separator));
}

.help_wrapper {
	top: 16px;
	right: 16px;
	display: flex;
	position: absolute;
	align-items: flex-end;
	flex-direction: column;
	justify-content: flex-start;
}

.help_item {
	gap: 8px;
	color: rgb(var(--dl-color-text-gray2));
	height: 36px;
	display: flex;
	padding: 0px 8px;
	position: relative;
	align-items: center;
	border-radius: 6px;
	justify-content: flex-start;
	cursor: pointer;
}

.help_item:hover {
	color: rgb(var(--dl-color-primary-100));
}

.help_text {
	color: currentcolor;
	font-size: 14px;
	letter-spacing: 0.3px;
}

.help_icon {
	fill: currentcolor;
	width: 12px;
	height: 12px;
}

.title_wrapper {
	display: flex;
	position: relative;
	align-self: flex-start;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	width: calc(100% - 180px);
}

.title_wrapper_icon_container {
	gap: 8px;
	display: flex;
	padding: 4px;
	align-items: center;
	border-color: rgb(var(--dl-color-borders-separator));
	border-width: 1px;
	border-radius: 6px;
	justify-content: flex-start;
}

.title_wrapper_icon {
	fill: rgb(var(--dl-color-primary-100));
	width: 17px;
	height: 16px;
}

.title_text {
	color: rgb(var(--dl-color-text-gray2));
	font-size: 12px;
	font-weight: var(--dl-fontweight-300);
	letter-spacing: 0.3px;
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.state_dropdown_wrapper {
	border-radius: 6px;
	width: 312px;
	height: 42px;
	position: relative;
}

.state_dropdown {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	border-color: rgb(var(--dl-color-borders-separator));
	border-width: 1px;
	border-radius: 6px;
	justify-content: space-between;
	background-color: rgb(var(--dl-color-backgrounds-page));
	cursor: pointer;
	position: relative;
}

.state_dropdown:hover {
	background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.state_dropdown_open {
	border-color: rgb(var(--dl-color-primary-100));
}

.state_dropdown_text_wrapper {
	margin: 8px 0px 8px 8px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.state_dropdown_text {
	color: rgb(var(--dl-color-text-black));
	font-size: 14px;
	margin-left: 12px;
	letter-spacing: 0.2px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 230px;
}

.state_dropdown_arrow {
	fill: rgb(var(--dl-color-text-gray2));
	width: 16px;
	height: 16px;
	margin-right: 16px;
	transition: .2s cubic-bezier(.785, .135, .15, .86) 0s;
}

.state_list {
	width: 100%;
	display: flex;
	overflow: hidden;
	position: relative;
	box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
	align-items: stretch;
	flex-direction: column;
	justify-content: flex-start;
	border-radius: 6px;
	background-color: rgb(var(--dl-color-backgrounds-white-on-white));
	z-index: 1;
	padding: 4px;
}

.state_list_scroll_wrapper {
	width: 100%;
	max-height: 200px;
	display: flex;
	overflow: auto;
	position: relative;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}

.state_list_empty {
	margin: 8px 12px;
	font-size: 12px;
	letter-spacing: 0.3px;
}

.state_list_item {
	width: 100%;
	height: 34px;
	display: flex;
	align-items: center;
	padding-left: 8px;
	padding-right: 8px;
	justify-content: space-between;
	cursor: pointer;
	transition: .2s ease;
	border-radius: 4px;
	flex-shrink: 0;
}

.state_list_item:hover {
	background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.state_list_item_left {
	gap: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.state_list_item_icon {
	fill: rgba(var(--dl-color-black), 0.65);
	width: 15px;
	height: 12px;
}

.state_list_item_text {
	color: rgb(var(--dl-color-text-black));
	font-size: 13px;
	letter-spacing: 0.3px;
	margin-top: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 188px;
}

.state_list_item_right {
	gap: 8px;
	display: flex;
	align-items: center;
	padding-right: 2px;
}

.state_list_item_icon_right {
	fill: rgba(var(--dl-color-text-black), 0.8);
	width: 15px;
	height: 15px;
	margin-top: 2px;
	cursor: default;
}

.state_list_item_icon_premium_box {
	padding: 3px 5px;
	border: 1px solid rgb(var(--dl-color-primary-100));
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(var(--dl-color-primary-100), 0.09);
	cursor: default;
}

.state_list_item_icon_premium {
	width: 12px;
	height: 12px;
	fill: rgb(var(--dl-color-primary-100));
}

.criteria_container {
	gap: 8px;
	height: auto;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	overflow: auto;
    position: absolute;
    inset: 0;
}

.criteria_container::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

.criteria_group {
	gap: 12px;
	display: flex;
	align-items: stretch;
	transition: ease .2s;
	padding-top: 4px;
	padding-left: 4px;
	flex-direction: column;
	padding-bottom: 4px;
	border-left: 2px solid rgba(var(--dl-color-text-black), 0.2);
	position: relative;
	border-radius: 4px;
}

.criteria_row {
	gap: 12px;
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	justify-content: flex-start;
	flex-grow: 1;
}

.criteria_connection {
	width: 50px;
	height: 24px;
	display: flex;
	position: relative;
	align-self: flex-start;
	margin-top: 3px;
	align-items: center;
	padding-left: 6px;
	border-radius: 4px;
	flex-direction: row;
	justify-content: flex-start;
	flex-shrink: 0;
}

.criteria_connection_text {
	color: rgb(var(--dl-color-text-black));
	font-size: 12px;
	align-self: center;
	font-style: normal;
	font-weight: var(--dl-fontweight-500);
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.criteria_connection_select {
	gap: 2px;
	width: 50px;
	height: 24px;
	display: flex;
	position: relative;
	align-self: flex-start;
	margin-top: 3px;
	align-items: center;
	padding-top: 2px;
	border: 1px solid rgba(var(--dl-color-text-black), 0.14);
	padding-left: 4px;
	border-radius: 4px;
	padding-right: 4px;
	flex-direction: row;
	padding-bottom: 2px;
	justify-content: center;
	cursor: pointer;
	overflow: hidden;
}

.criteria_connection_select:hover {
	background-color: rgba(var(--dl-color-text-black), 0.05);
}

.criteria_connection_select_disabled {
	pointer-events: none;
	background-color: rgba(var(--dl-color-text-black), 0.05);
	border-color: rgba(var(--dl-color-text-black), 0.05);
}

.criteria_connection_select_text_wrapper {
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	align-items: center;
	justify-content: flex-start;
	transition: ease .2s;
}

.criteria_connection_select_text {
	color: rgb(var(--dl-color-text-black));
	font-size: 12px;
	align-self: center;
	text-align: center;
	font-weight: var(--dl-fontweight-500);
	letter-spacing: 0.5px;
	text-transform: uppercase;
	height: 24px;
}

.criteria_connection_select_text_disabled {
	color: rgba(var(--dl-color-text-black), 0.6);
}

.criteria_connection_select_icon_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.criteria_connection_select_icon {
	fill: rgba(var(--dl-color-black), 0.4);
	width: 12px;
	height: 12px;
	margin-right: -3px;
	flex-shrink: 0;
}

.criteria_condition_wrapper {
	flex-grow: 1;
	display: flex;
	align-self: flex-start;
	align-items: flex-start;
	flex-direction: row;
	justify-content: space-between;
	gap: 4px;
	position: relative;
	flex-grow: 1;
}

.delete_icon {
	fill: rgba(var(--dl-color-text-black), 0.8);
	width: 15px;
	height: 15px;
	transition: ease .2s;
	cursor: pointer;
	margin-right: 6px;
	margin-top: 6px;
	flex-shrink: 0;
}

.delete_icon:hover {
	fill: rgb(var(--dl-color-colors-red-bright));
}

.criteria_add_row {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-right: 4px;
	transition: ease .2s;
	overflow: hidden;
}

.criteria_add_row_left {
	gap: 8px;
	display: flex;
	align-items: flex-start;
	flex-direction: row;
}

.criteria_add_condition {
	gap: 4px;
	color: rgb(var(--dl-color-text-black));
	display: flex;
	transition: ease .2s;
	align-items: center;
	padding: 4px 8px;
	border-radius: 4px;
	justify-content: center;
	cursor: pointer;
}

.criteria_add_condition:hover {
	background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.criteria_add_plus_icon {
	fill: currentColor;
	width: 10px;
	height: 10px;
}

.criteria_add_text {
	color: currentColor;
	font-size: 13px;
	letter-spacing: 0.2px;
}

.criteria_add_group {
	background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.criteria_add_group:hover {
	background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.criteria_delete_group {
	color: rgba(var(--dl-color-text-black), 0.8);
}

.state_description_wrapper {
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.state_description_input {
	resize: none;
	border: none;
	width: 100%;
	height: 48px;
	font-size: 12px;
	letter-spacing: 0.2px;
	padding: 4px 6px 0px;
	color: rgba(var(--dl-color-text-gray1), 0.97);
	line-height: 1.2;
	grid-area: 1 / 1 / 2 / 2;
	align-self: flex-start;
}

.state_description_input::placeholder {
	color: rgba(var(--dl-color-text-gray1), 0.7);
}

.state_description_input:hover {
	background-color: rgba(var(--dl-color-backgrounds-hover1), 0.5);
}

.state_description_input:focus {
	background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.state_description_input::-webkit-scrollbar {
	display: none;
}

.state_description_error {
	position: absolute;
	top: 50px;
	left: 2px;
	color: rgb(var(--dl-color-colors-red-error));
}