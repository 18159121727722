.container {
  height: 36px;
  display: flex;
  padding: 0px 16px 0px 10px;
  position: relative;
  background: rgb(var(--dl-color-backgrounds-topnav));
  align-items: center;
  border-radius: 18px;
  justify-content: center;
}

.middle_item_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.middle_item {
  color: rgb(var(--dl-color-text-black));
  height: 32px;
  display: flex;
  padding: 6px 16px;
  align-items: center;
  border-radius: 32px;
  justify-content: center;
  cursor: pointer;
}

.middle_item_text {
  color: currentColor;
  font-size: 15px;
  font-weight: var(--dl-fontweight-500);
  letter-spacing: 0.5px;
}

.middle_item_popup_container {
  padding-top: 6px;
}

.middle_item_popup {
  max-width: 250px;
  width: 100vw;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 8px;
  box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
  border-radius: 6px;
  background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.middle_item_popup_top {
  gap: 8px;
  cursor: pointer;
  display: flex;
  align-items: stretch;
  border-radius: 6px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 8px 6px;
}

.middle_item_popup_top:hover {
  background-color: rgb(var(--dl-color-backgrounds-hover3));
  color: rgb(var(--dl-color-black));
}

.middle_item_popup_top_title_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  height: 14px;
  color: currentColor;
}

.middle_item_popup_top_title {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: currentColor;
  font-weight: var(--dl-fontweight-500);
}

.middle_item_popup_top_info {
  padding: 2px 8px;
  border-radius: 20px;
  background-color: rgb(var(--dl-color-primary-100));
}

.middle_item_popup_top_info_text {
  font-size: 10px;
  letter-spacing: 0.5px;
  font-weight: var(--dl-fontweight-500);
  color: rgb(var(--dl-color-white-always));
}

.middle_item_popup_top_text {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: currentColor;
}

.middle_item_popup_top_button {
  color: rgb(var(--dl-color-primary-100));
  display: flex;
  align-items: center;
  border-radius: 6px;
  justify-content: space-between;
}

.middle_item_popup_top_button:hover {
  color: rgb(var(--dl-color-primary-300));
}

.middle_item_popup_top_button_title {
  color: currentColor;
  font-size: 12px;
  letter-spacing: 0.5px;
  overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  font-weight: var(--dl-fontweight-500);
}

.middle_item_popup_separator {
  height: 1px;
  margin: 4px 0px;
  display: flex;
  background-color: rgb(var(--dl-color-borders-separator-popup));
}

.middle_item_popup_scroll_wrapper {
  max-height: 300px;
  overflow: auto;
}

.middle_item_popup_button {
  cursor: pointer;
  height: 36px;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  border-radius: 6px;
  justify-content: space-between;
}

.middle_item_popup_button:hover {
  background-color: rgb(var(--dl-color-backgrounds-hover3));
  color: rgb(var(--dl-color-black));
}

.middle_item_popup_button_text {
  color: currentColor;
  font-size: 14px;
  letter-spacing: 0.5px;
  overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.middle_item_popup_button_icon {
  fill: currentColor;
  width: 18px;
  height: 18px;
}

.middle_search_button {
  color: rgb(var(--dl-color-text-black));
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  border-radius: 15px;
  justify-content: center;
  cursor: pointer;
}

.middle_search_button:hover {
  color: rgb(var(--dl-color-black));
  background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.middle_search_button_icon {
  fill: currentColor;
  width: 14px;
  height: 14px;
}