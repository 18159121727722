.background_image {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image_svg {
  left: 0px;
  right: 0px;
  top: -400px;
  z-index: 1;
  position: relative;
  margin-right: auto;
  width: 100%
}

.warning_row {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
}

.warning_icon {
  margin-left: 1px;
  fill: rgb(var(--dl-color-colors-red-error));
  width: 12px;
  height: 12px;
}

.warning_text {
  color: rgb(var(--dl-color-colors-red-error));
  align-self: flex-end;
  font-style: normal;
  margin-left: 5px;
  margin-bottom: 1px;
}

.info_wrapper {
  width: 100%;
}

.info_container {
  width: 100%;
  border: 1px solid rgb(var(--dl-color-colors-red-error));
  border-radius: 8px;
  background-color: rgba(var(--dl-color-colors-red-error), 0.05);
  padding: 8px;
  color: rgb(var(--dl-color-colors-red-error));
  margin-bottom: 8px;
  font-size: 12px;
}

.info_container.green {
  border-color: rgb(var(--dl-color-colors-green)) !important;
  background-color: rgba(var(--dl-color-colors-green), 0.05) !important;
  color: rgb(var(--dl-color-colors-green)) !important;
}