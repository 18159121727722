.widget_container {
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover-light));
}

.widget_wrapper {
    width: 100%;
    height: calc(100% + 18px);
    margin-top: -18px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    align-items: stretch;
}

.drag_icon {
    height: 16px;
    width: 16px;
    fill: currentColor;
}