.wrapper {
  padding: 16px 8px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
}

.search_row {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0px 8px;
}

.condition_search {
  gap: 6px;
  width: 200px;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0px 12px;
  border-radius: 6px;
  justify-content: flex-start;
  border: 1px solid rgb(var(--dl-color-borders-separator));
  height: 36px;
}

.condition_search_icon {
  fill: rgb(var(--dl-color-text-gray4));
  width: 18px;
  height: 18px;
}

.condition_search_input {
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  font-weight: var(--dl-fontweight-300);
  letter-spacing: 0.3px;
  border: none;
  border-radius: 0px;
  height: 16px;
  line-height: 1;
  flex: 1 1;
}

.condition_search_input::placeholder {
  color: rgba(var(--dl-color-text-gray4), 0.63);
}

.condition_search_delete {
  height: 18px;
  width: 18px;
  color: rgba(var(--dl-color-text-black), 0.6);
  border-radius: 4px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
}

.condition_search_delete:hover {
  color: rgba(var(--dl-color-text-black), 0.8);
  background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.condition_search_delete_icon {
  width: 16px;
	height: 16px;
  fill: currentColor;
}

.categories_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
  margin-top: 24px;
  height: calc(90vh - 220px);
  width: 100%;
  gap: 24px;
  position: relative;
}

.category_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 8px;
}

.category_title_row {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
}

.category_title {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: rgba(var(--dl-color-text-black), 0.6);
}

.conditions_wrapper {
  display: grid;
  grid-auto-flow: dense;
  gap: 8px;
  margin: 0px 8px;
  grid-template-columns: repeat(auto-fit, calc(50% - 8px));
  grid-auto-rows: max-content;
}

.card_container {
  gap: 12px;
  display: flex;
  min-height: 180px;
  padding: 16px 16px;
  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(var(--dl-color-shadow), 0.2);
  transition: 0.3s;
  align-items: stretch;
  border-color: rgb(var(--dl-color-borders-separator));
  border-width: 1px;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.card_container:hover {
  box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
  background-color: rgba(var(--dl-color-backgrounds-hover2), 0.2);
}

.card_most_used_icon_wrapper {
  position: absolute;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 8px;
  gap: 4px;
  top: 16px;
  left: 16px;
  background-color: rgba(var(--dl-color-primary-100), 0.8);
}

.card_favourite_icon_wrapper {
  position: absolute;
  height: 20px;
  border: 1px solid rgb(var(--dl-color-borders-separator-popup));
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 8px;
  gap: 4px;
}

.card_favourite_icon_wrapper:hover {
  background-color: rgba(var(--dl-color-text-black), 0.05);
}

.card_favourite_icon_text {
  font-size: 11px;
  letter-spacing: 0.3;
  color: rgba(var(--dl-color-black), 0.6);
}

.card_favourite_icon {
  fill: rgba(var(--dl-color-black), 0.3);
  width: 14px;
  height: 14px;
}

.card_icons {
  gap: 8px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.card_text_wrapper {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.card_description_title {
  color: rgb(var(--dl-color-text-black));
  font-size: 16px;
  font-weight: var(--dl-fontweight-500);
  letter-spacing: 0.5px;
  text-align: center;
}

.card_description_description {
  color: rgba(var(--dl-color-text-gray1), 0.72);
  padding: 0px 8px;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.2px;
}

.card_condition_row {
  display: flex;
  align-items: center;
  justify-content: center;
}