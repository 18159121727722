.wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.error_header {
    margin-top: 200px;
    font-size: 32px;
}
.error_subheader {
    margin-top: 16px;
    font-size: 16px;
}
.button_home_link {
    width: 200px;
}
.button_home {
height: 50px;
width: 200px;
font-size: 14px;
box-shadow: 0px 9px 23px 0px rgba(var(--dl-color-primary), 0.5);
font-style: normal;
margin-top: 32px;
text-align: center;
transition: 0.1s;
letter-spacing: 0.3px;
border-width: 0px;
text-decoration: none;
}