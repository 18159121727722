.create_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.create_button {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    border-radius: 30px;
    background-color: rgb(var(--dl-color-primary-100));
    white-space: nowrap;
    cursor: pointer;
}

.create_button:hover {
    background-color: rgb(var(--dl-color-primary-300));
}

.create_button_text {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: rgb(var(--dl-color-white-always));
}

.create_button_popup {
    width: 300px;
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border-radius: 6px;
    padding: 8px;
    z-index: 2001;
}

.create_button_popup_item {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgb(var(--dl-color-text-black));
    flex-grow: 1;
    width: 100%;
    cursor: pointer;
    padding: 0px 8px;
    border-radius: 6px;
    gap: 8px;
}

.create_button_popup_item:hover {
    color: rgb(var(--dl-color-black));
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.create_button_popup_item_icon {
    fill: currentColor;
    width: 14px;
    height: 14px;
}

.create_button_popup_item_text {
    color: currentColor;
    font-size: 13px;
    letter-spacing: 0.3px;
    white-space: nowrap;
}