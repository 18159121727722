/* PLACEHOLDER */
.react-grid-item.react-grid-placeholder {
    background-color: rgba(var(--dl-color-primary-100), 0.4);
}

/* RESIZE HANDLE */
.react-grid-item>.react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(var(--dl-color-primary-100), 1);
    border-bottom: 2px solid rgba(var(--dl-color-primary-100), 1);
    z-index: 1;
}

.WidgetDraggableHandle {
    color: rgba(var(--dl-color-text-black), 0.8);
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin: 6px 80px 0px 0px;
    z-index: 1;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
    transition: ease .3s;
}

.WidgetDraggableHandle:hover {
    color: rgb(var(--dl-color-text-black));
}

.WidgetDraggableHandle:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}