html::-webkit-scrollbar {
    display: none;
}

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    justify-content: stretch;
    background-color: rgb(var(--dl-color-primary-100));
}

.page_content {
    gap: 280px;
    flex: 0 0 auto;
    width: 66%;
    height: auto;
    display: flex;
    padding: 320px 5% 320px 10%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgb(var(--dl-color-backgrounds-page));
}

.page_content::-webkit-scrollbar {
    display: none;
}

.reference {
    opacity: 0;
    width: 1px;
    height: 1px;
}

.content_section {
    gap: 32px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}

.content_section_traddle_logo {
    width: 45px;
    height: 45px;
    padding: 6px;
    box-shadow: 2px 1px 13px 0px rgba(var(--dl-color-shadow), 0.3);
    background-color: rgb(var(--dl-color-white-always));
    object-fit: cover;
    border-radius: 50%;
}

.content_section_wrapper {
    width: 100%;
    gap: 16px;
    flex: 1;
    width: auto;
    display: flex;
    align-items: stretch;
    padding-top: 8px;
    flex-direction: column;
}

.section_start_wrapper {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.text_big {
    font-size: 18px;
}

.margin_top_8 {
    margin-top: 8px;
}

.next_button {
    width: 120px;
    margin-top: 8px;
}

.button_no_margin {
    margin-top: 0px;
}

.avatar_container {
    width: 100%;
    column-gap: 32px;
    row-gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: row;
}

.avatar_selection_switch {
    column-gap: 32px;
    row-gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: row;
}

.upload_image {
    gap: 8px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    border: 1px dotted rgba(var(--dl-color-text-black), 0.3);
    border-radius: 24px;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-size: cover;
}

.upload_image:hover {
    color: rgb(var(--dl-color-primary-100));
    border-color: rgb(var(--dl-color-primary-100));
}

.upload_image_content {
    gap: 6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: rgba(var(--dl-color-text-black), 0.6);
}

.upload_image_input_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.upload_image_input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 120px;
    bottom: 0px;
    border: none;
}

.upload_text_wrapper {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.upload_text {
    font-size: 9px;
    text-align: center;
    color: rgb(var(--dl-color-primary-100));
}

.upload_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload_overlay:hover {
    opacity: 1;
    background-color: rgba(var(--dl-color-black), 0.2);
}

.avatar_separator_container {
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avatar_separator_line {
    height: 40px;
    width: 1px;
    background-color: rgb(var(--dl-color-borders-separator));
}

.avatar_separator_text {
    color: rgb(var(--dl-color-text-textplaceholder));
    font-size: 10px;
    margin: 8px 0px;
}

.avatar_color {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    border-radius: 24px;
    justify-content: center;
    border: none;
    color: rgb(var(--dl-color-white-always));
    overflow: hidden;
}

.avatar_color_text {
    color: rgb(var(--dl-color-white-always));
    font-size: 54px;
}

.avatar_color_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(var(--dl-color-white), 0.5);
}

.avatar_icon_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: relative;
    flex: 1;
    overflow: visible;
    width: 100%;
}

.avatar_color_options {
    column-gap: 16px;
    row-gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 16px;
}

.avatar_color_option {
    width: 18px;
    height: 18px;
    transition: transform .2s cubic-bezier(.785, .135, .15, .86) 0s;
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar_color_option:hover {
    transform: scale(1.3);
    box-shadow: 0px 0px 8px -4px rgb(var(--dl-color-primary-100));
}

.avatar_color_option_icon_check {
    width: 12px;
    height: 12px;
    fill: rgb(var(--dl-color-white-always));
}

.avatar_icon_separator {
    height: 1px;
    margin-top: 16px;
    background-color: rgb(var(--dl-color-borders-separator));
}

.icon_selector_wrapper {
    padding-right: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.icon_search_input_wrapper {
    width: 160px;
    height: 24px;
    margin: 8px 6px 8px 8px;
    position: relative;
}

.icon_search_input {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 24px;
    border-color: rgb(var(--dl-color-borders-separator));
    font-size: 11px;
}

.icon_search_input_icon {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 6px;
    left: 8px;
    color: rgb(var(--dl-color-text-gray2));
}

.icon_search_delete {
    top: 6px;
    right: 8px;
    width: 12px;
    height: 12px;
    display: flex;
    position: absolute;
    align-self: center;
    align-items: center;
    border-radius: 8px;
    padding: 2px;
    justify-content: center;
    background-color: rgb(var(--dl-color-primary-100));
    cursor: default;
}

.icon_search_delete:hover {
    background-color: rgb(var(--dl-color-primary-300));
}

.icon_search_delete_icon {
    fill: rgb(var(--dl-color-white-always));
    width: 9px;
    height: 9px;
}

.icons_list_wrapper {
    height: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: scroll;
    padding: 4px 4px;
    gap: 8px;
}

.avatar_icon_list_item {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    transition: transform .2s cubic-bezier(.785, .135, .15, .86) 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.avatar_icon_list_item:hover {
    transform: scale(1.1);
    background-color: rgb(var(--dl-color-backgrounds-page));
}

.error_text {
    color: rgb(var(--dl-color-colors-red-error));
}

.theme_color_options {
    column-gap: 32px;
    row-gap: 24px;
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.theme_option_container {
    width: 18px;
    height: 18px;
    position: relative;
    overflow: visible;
    cursor: pointer;
}

.theme_option {
    width: 18px;
    height: 18px;
    position: absolute;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.theme_option:hover {
    top: -5px;
    left: -5px;
    width: 28px;
    height: 28px;
    box-shadow: 0px 0px 8px -4px rgb(var(--dl-color-primary-100));
}

.theme_option_icon_check {
    width: 24px;
    height: 24px;
    fill: rgb(var(--dl-color-white-always));
}

.usage_options_container {
    gap: 8px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.usage_option {
    flex: 0 0 auto;
    width: auto;
    height: 40px;
    display: flex;
    transition: .2s cubic-bezier(.785, .135, .15, .86) 0s;
    align-items: center;
    border-width: 1px;
    padding: 0px 24px;
    border-radius: 4px;
    justify-content: center;
    cursor: pointer;
    border-color: rgba(var(--dl-color-text-black), 0.3);
    font-weight: var(--dl-fontweight-500);
}

.usage_option:hover {
    border-color: rgb(var(--dl-color-primary-100));
    color: rgb(var(--dl-color-primary-100));
}

.usage_option_text {
    font-size: 12px;
}

.usage_button_container {
    gap: 24px;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.usage_notice_text {
    color: rgb(var(--dl-color-text-gray2));
    font-size: 11px;
}

.find_textinput {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 8px;
    border-color: rgb(var(--dl-color-primary-100));
    border-radius: 0px;
    border-width: 0px;
    padding-bottom: 6px;
    border-bottom-width: 1px;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

.screener_textinput {
    font-size: 20px;
    font-weight: var(--dl-fontweight-500);
}

.screener_input_hint {
    color: rgb(var(--dl-color-primary-100));
    font-size: 11px;
    margin-top: 4px;
}

.button_start {
    width: 140px
}

.full_width_bar_fixed_top {
    width: 66%;
    height: auto;
    position: fixed;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 100;
}

.full_width_bar_fixed_bottom {
    width: 66%;
    height: auto;
    position: fixed;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 100;
}

.scroll_top {
    margin-right: 24px;
    margin-bottom: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    align-self: flex-end;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    background-color: rgb(var(--dl-color-primary-100));
    color: rgb(var(--dl-color-white-always));
    cursor: pointer;
    transition: background-color .2s cubic-bezier(.785, .135, .15, .86) 0s;
}

.scroll_top:hover {
    background-color: rgb(var(--dl-color-primary-300));
    box-shadow: 0px 0px 8px -4px rgb(var(--dl-color-primary-100));
}

.logout_button {
    margin-top: 24px;
    margin-right: 24px;
    width: 80px;
    height: 40px;
    position: relative;
}

.logout_button_white {
    background-color: rgb(var(--dl-color-backgrounds-white));
    border: 1px solid rgb(var(--dl-color-primary-100)) !important;
    color: rgb(var(--dl-color-primary-100));
}

.logout_button_white:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    box-shadow: none;
}

@media only screen and (max-width: 1319px) {
    .avatar_color_options {
        column-gap: 17%;
    }

    .usage_button_container {
        gap: 16px;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 1169px) {
    .avatar_container {
        flex-direction: column;
    }

    .avatar_selection_switch {
        max-width: 280px;
        gap: 0px;
        justify-content: space-between;
        width: 100%;
    }

    .avatar_color_options {
        column-gap: 0px;
        margin-top: 8px;
    }

    .page_content {
        width: 80%;
        padding: 304px 5% 304px 8%;
        gap: 304px;
    }
}

@media only screen and (max-width: 991px) {
    .full_width_bar_fixed_top {
        width: 80%;
    }

    .full_width_bar_fixed_bottom {
        width: 80%;
    }
}

@media only screen and (max-width: 767px) {
    .theme_color_options {
        column-gap: 20%;
    }
}

@media only screen and (max-width: 479px) {
    .full_width_bar_fixed_top {
        width: 100%;
    }

    .full_width_bar_fixed_bottom {
        width: 100%;
    }

    .page_content {
        width: 100%;
        padding: 304px 4% 304px 4%;
    }

    .content_section {
        gap: 16px;
    }

    .content_section_traddle_logo {
        width: 35px;
        height: 35px;
    }

    .content_section_wrapper {
        padding-top: 0px;
    }
}

@media only screen and (max-width: 399px) {
    .avatar_color_options {
        column-gap: 17%;
    }
}