.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 42px;
  position: relative;
  background-color: rgb(var(--dl-color-backgrounds-page-back));
}

.separator {
  height: 4px;
  background-color: rgb(var(--dl-color-backgrounds-page-back));
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  cursor: n-resize;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 1;
  flex-shrink: 1;
  height: 100%;
  border-top-left-radius: 6px;
  overflow: hidden;
  background-color: rgb(var(--dl-color-backgrounds-gray-light));
}
