.toggle_switch {
    width: 42px;
    height: 22px;
    display: flex;
    padding: 4px;
    align-items: center;
    border-radius: 11px;
    justify-content: flex-start;
    background-color: rgba(var(--dl-color-text-gray5), 0.4);
    cursor: pointer;
    transition: ease .3s;
}

.toggle_switch:hover {
    background-color: rgba(var(--dl-color-text-gray5), 0.7);
}

.toggle {
    width: 16px;
    height: 16px;
    display: flex;
    align-self: center;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(var(--dl-color-white-always));
}

.toggle_switch_big {
    border: 1px solid rgb(var(--dl-color-backgrounds-hover3));
    padding: 0px 8px;
    border-radius: 6px;
    height: 36px;
    max-width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.toggle_switch_big:hover {
    background-color: rgba(var(--dl-color-backgrounds-hover2), 0.3);
}

.toggle_big_background {
    border-radius: 6px;
    position: absolute;
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    top: 4px;
    bottom: 4px;
    right: calc(50% + 8px);
    left: 8px;
    transition: ease .2s;
}

.toggle_big_background_toggled {
    left: calc(50% + 8px);
    right: 8px;
    background-color: rgb(var(--dl-color-primary-100))
}

.toggle_big_text {
    font-size: 13px;
    line-height: 1;
    width: 46%;
    text-align: center;
    z-index: 1;
}