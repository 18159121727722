.column_wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-shrink: 1;
    padding: 8px 0px;
    gap: 8px;
    width: 50%;
    position: relative;
}

.save_as_template_row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 24px;
}

.save_as_template_button {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover3));
    padding: 0px 8px;
    cursor: pointer;
    flex-shrink: 0;
}

.save_as_template_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    border-color: rgb(var(--dl-color-backgrounds-hover2));
}

.save_as_template_button_text {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 13px;
}

.save_as_template_input_wrapper {
    margin-right: 8px;
	gap: 6px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 8px;
    height: 32px;
    flex-shrink: 1;
    width: 250px;
    border-radius: 6px;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover3));
    transition: ease .2s;
}

.save_as_template_input {
	display: flex;
    align-items: flex-start;
    font-size: 13px;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.3px;
    border: none;
    border-radius: 0px;
    height: 18px;
    overflow: hidden;
    line-height: 1;
    flex: 1 1;
}

.save_as_template_input::placeholder {
	color: rgba(var(--dl-color-text-gray4), 0.63);
}

.save_as_template_input_delete {
	height: 22px;
    width: 22px;
    color: rgba(var(--dl-color-text-black), 0.6);
    border-radius: 4px;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
}

.save_as_template_input_delete:hover {
	color: rgba(var(--dl-color-text-black), 0.8);
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.save_as_template_input_delete_icon {
	width: 18px;
	height: 18px;
    fill: currentColor;
}

.save_as_template_accept_button {
    width: 24px;
    height: 24px;
    background-color: rgb(var(--dl-color-primary-100));
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.save_as_template_accept_button:hover {
    background-color: rgb(var(--dl-color-primary-300));
}

.save_as_template_accept_icon {
    fill: rgb(var(--dl-color-white-always));
    width: 18px;
    height: 18px;
}

.text_between {
    margin-top: 4px;
    color: rgb(var(--dl-color-text-gray3));
    font-size: 12px;
    margin-left: 24px;
}

.columns_list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
    padding: 0px 4px;
    flex-shrink: 1;
    overflow: auto;
}

.added_column_row_drag_line {
    height: 2px;
    background-color: rgb(var(--dl-color-primary-100));
    margin-left: 16px;
}

.added_column_row_draggable {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    cursor: move;
}

.added_column_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.added_column_button {
    height: 30px;
    padding: 0px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover2));
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    border-radius: 6px;
    gap: 8px;
    transition: ease .2s;
}

.added_column_button_text {
    font-size: 13px;
    line-height: 1;
}

.added_column_button_separator {
    height: 16px;
    width: 1px;
    background-color: rgba(var(--dl-color-text-black), 0.8);
}

.added_column_button_option_dropdown {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 4px 0px 6px;
    height: 22px;
    cursor: default;
    gap: 4px;
}

.added_column_button_option_dropdown:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.added_column_button_option_dropdown_text {
    margin-top: 1px;
    font-size: 12px;
    line-height: 1;
}

.added_column_button_option_dropdown_arrow_icon {
    fill: rgba(var(--dl-color-text-black), 0.8);
    width: 14px;
    height: 14px;
    transition: ease .2s;
}

.added_column_button_option_popup {
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border-radius: 4px;
    padding: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 1;
    min-width: 100%;
}

.added_column_button_option_popup_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 4px;
    border-radius: 4px;
    height: 26px;
    white-space: nowrap;
    flex-wrap: nowrap;
    cursor: default;
    gap: 2px;
    flex-shrink: 0;
    font-size: 12px;
}

.added_column_button_option_popup_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.column_wrapper_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.added_column_drag_wrapper {
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.added_column_drag_wrapper:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}

.added_column_drag_icon {
    fill: rgb(var(--dl-color-text-gray3));
    width: 20px;
    height: 20px;
}

.added_column_delete_icon {
    fill: rgb(var(--dl-color-text-gray3));
    height: 15px;
    width: 15px;
    cursor: pointer;
}

.added_column_delete_icon:hover {
    fill: rgb(var(--dl-color-colors-red-bright));
}

.search_row {
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 100%;
}

.search_wrapper {
	gap: 6px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 8px;
    height: 32px;
    max-width: 260px;
    border-radius: 6px;
    border: 1px solid rgb(var(--dl-color-backgrounds-hover3));
    margin-left: 24px;
    flex-grow: 1;
}

.search_icon {
	fill: rgb(var(--dl-color-text-gray4));
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.search_input {
	display: flex;
    align-items: flex-start;
    font-size: 13px;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.3px;
    border: none;
    border-radius: 0px;
    height: 18px;
    overflow: hidden;
    line-height: 1;
    flex: 1 1;
}

.search_input::placeholder {
	color: rgba(var(--dl-color-text-gray4), 0.63);
}

.search_delete {
	height: 22px;
    width: 22px;
    color: rgba(var(--dl-color-text-black), 0.6);
    border-radius: 4px;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search_delete:hover {
	color: rgba(var(--dl-color-text-black), 0.8);
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.search_delete_icon {
	width: 18px;
	height: 18px;
    fill: currentColor;
}

.added_column_add_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    height: 28px;
    background-color: rgb(var(--dl-color-primary-100));
    border-radius: 6px;
    margin-left: 8px;
    cursor: pointer;
}

.added_column_add_button:hover {
    background-color: rgb(var(--dl-color-primary-300));
}

.blue_button_disabled {
    cursor: not-allowed;
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.blue_button_disabled:hover {
    background-color: rgb(var(--dl-color-borders-separator-popup), 0.5);
}

.added_column_add_button_text {
    color: rgb(var(--dl-color-white-always));
    font-size: 13px;
    line-height: 1;
}