.screener_icon {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: rgb(var(--dl-color-white-always));
}

.screener_icon_letter {
    margin-top: 2px;
    color: rgb(var(--dl-color-white-always));
    font-size: 32px;
}