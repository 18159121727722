.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 42px;
    background-color: rgb(var(--dl-color-backgrounds-page));
    z-index: 1;
    position: relative;
}

.separator_wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 4px;
    flex-shrink: 0;
    cursor: n-resize;
    z-index: 100;
}

.separator {
    height: 4px;
    background-color: rgb(var(--dl-color-backgrounds-page-back));
}

.wrapper {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    background-color: rgb(var(--dl-color-backgrounds-page-back));
    z-index: 1;
    flex-grow: 1;
    height: 0px;
}

.screener_list_container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-shrink: 0;
   
}

.screener_list_separator {
    background-color: rgb(var(--dl-color-backgrounds-page-back));
    cursor: e-resize;
    width: 4px;
}

.screener_list_wrapper {
    overflow: hidden;
    border-top-right-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
    flex-shrink: 0;
    width: 300px;
}

.add_screener_button {
    height: 38px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 8px;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    flex-shrink: 0;
}

.add_screener_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover-light));
}

.add_screener_button_text {
    font-size: 14px;
}

.add_button_icon_wrapper {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: rgb(var(--dl-color-text-black));
}

.add_button_icon_wrapper:hover {
    color: rgb(var(--dl-color-black));
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.add_button_icon {
    fill: currentColor;
    width: 24px;
    height: 24px;
}

.screener_list {
    flex-grow: 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 8px;
    padding-left: 4px;
    padding-top: 8px;
    border-top: 1px solid rgb(var(--dl-color-borders-separator-popup));
}

.screener_list::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.screener_wrapper {
    gap: 16px;
    display: flex;
    padding: 16px;
    align-items: stretch;
    border: 1px solid rgb(var(--dl-color-borders-separator));
    border-radius: 12px;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
}

.screener_wrapper:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover-light));
}

.screener_wrapper_selected {
    border-color: rgb(var(--dl-color-primary-100));
}

.screener_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 4px;
}

.screener_description {
    font-size: 12px;
}

.screener_row_left {
    gap: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 1px;
    flex-grow: 1;
}

.screener_row_logo_wrapper {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-skeleton-dark));
    position: relative;
}

.screener_name {
    font-size: 15px;
    font-weight: var(--dl-fontweight-500);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.screener_settings_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
}

.screener_settings_container:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.screener_settings_icon {
    fill: rgb(var(--dl-color-text-black));
    width: 22px;
    height: 22px;
}

.screener_settings_popup {
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    border-radius: 6px;
    z-index: 10;
}

.screener_settings_popup_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
}

.screener_settings_popup_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.screener_settings_popup_item_delete {
    color: rgb(var(--dl-color-colors-red-error));
}

.screener_settings_popup_item_delete:hover {
    background-color: rgba(var(--dl-color-colors-red-error), 0.1);
}

.screener_settings_popup_item_text {
    font-size: 13px;
    color: currentColor;
    white-space: nowrap;
}

.screener_running_button_bg {
    background-color: rgb(var(--dl-color-backgrounds-page));
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
}

.screener_running_button {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    height: 28px;
    border: 1px solid rgba(var(--dl-color-colors-green), 0.34);
    border-radius: 8px;
    justify-content: center;
    background-color: rgba(var(--dl-color-colors-green), 0.14);
}

.screener_running_button_text {
    font-size: 12px;
    letter-spacing: 0.3px;
}

.screener_input_list_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    border-radius: 30px;
    height: 28px;
}

.screener_input_list_logo_wrapper {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-skeleton-dark));
    position: relative;
}

.screener_input_list_logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.screener_input_list_name {
    overflow: hidden;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.screener_input_list_count_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    border-radius: 30px;
    padding: 0px 4px;
    height: 28px;
    min-width: 28px;
}

.screener_input_list_count {
    font-size: 13px;
    line-height: 1;
}

.wrapper_row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 32px;
    gap: 16px;
}

.wrapper_column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 32px;
    gap: 16px;
}

.delete_container {
    gap: 16px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
    overflow: hidden;
}

.delete_header {
    color: rgb(var(--dl-color-primary-100));
    font-size: 16px;
    text-align: left;
    font-weight: var(--dl-fontweight-500);
    letter-spacing: 0.2px;
}

.delete_avatar_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete_state_name {
    color: rgba(var(--dl-color-text-black), 0.78);
    font-size: 15px;
    align-self: flex-start;
    text-align: left;
    letter-spacing: 0.3px;
}

.delete_state_warning {
    font-size: 13px;
    align-self: center;
    text-align: left;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.2px;
    margin-top: 8px;
}

.text_bold_500 {
    font-weight: var(--dl-fontweight-500);
}

.delete_confirmation_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.delete_confirmation_text {
    font-size: 12px;
    color: rgba(var(--dl-color-text-black), 0.75);
    font-weight: var(--dl-fontweight-500);
    margin-left: 2px;
    align-self: flex-start;
}

.delete_confirmation_input {
    height: 42px;
    border-color: rgb(var(--dl-color-backgrounds-hover3));
    width: 250px;
    border-radius: 6px;
    padding: 0px 8px;
    text-align: center;
    font-size: 13px;
    color: rgb(var(--dl-color-text-black));
}

.delete_confirmation_input::placeholder {
    color: rgb(var(--dl-color-text-black), 0.57)
}

.delete_confirmation_input:focus {
    border-color: rgb(var(--dl-color-primary-100));
}

.delete_state_button_row {
    gap: 24px;
    display: flex;
    margin-top: 16px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.delete_state_button {
    width: 110px;
    height: 42px;
    display: flex;
    align-items: center;
    border-color: rgb(var(--dl-color-primary-100));
    border-width: 1px;
    border-radius: 6px;
    justify-content: center;
    color: rgb(var(--dl-color-primary-100));
    cursor: pointer;
}

.delete_state_button:hover {
    background-color: rgba(var(--dl-color-primary-100), 0.09);
}

.delete_state_button_text {
    color: currentColor;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 1;
}

.delete_state_button_delete {
    background-color: rgb(var(--dl-color-primary-100));
    color: rgb(var(--dl-color-white));
}

.delete_state_button_delete:hover {
    background-color: rgb(var(--dl-color-primary-300));
    border-color: rgb(var(--dl-color-primary-300));
}