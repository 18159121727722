.profile_menu_wrapper {
    fill: rgb(var(--dl-color-text-gray5));
    color: rgb(var(--dl-color-text-black));
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 8px;
}

.profile_menu_container {
    width: 28px;
    cursor: pointer;
    height: 28px;
    display: flex;
    align-items: flex-start;
    border-radius: 50%;
    justify-content: flex-end;
}

.profile_menu_container:hover {
    opacity: 0.9;
}

.avatar_icon_wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(var(--dl-color-white));
    font-size: 12px;
}

.profile_image {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: rgb(var(--dl-color-white));
    fill: rgb(var(--dl-color-white));
}

.profile_menu {
    z-index: 100;
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    align-self: flex-end;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    border-radius: 6px;
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
}

.profile_menu_top {
    width: 100%;
    display: flex;
    padding: 16px 24px 24px;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.profile_menu_top_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.profile_menu_top_header_left {
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_menu_top_header_states {
    color: rgba(var(--dl-color-white-always), 0.8);
    font-size: 15px;
    font-weight: var(--dl-fontweight-500);
    letter-spacing: 0.3px;
}

.profile_menu_top_header_states_icon {
    fill: rgba(var(--dl-color-text-black), 0.57);
    width: 12px;
    height: 12px;
    align-self: center;
    cursor: pointer;
}

.profile_menu_top_header_states_icon:hover {
    fill: rgba(var(--dl-color-text-black), 0.8);
}

.profile_menu_top_header_upgrade {
    gap: 4px;
    fill: rgb(var(--dl-color-primary-300));
    color: rgb(var(--dl-color-primary-300));
    display: flex;
    padding: 4px 8px;
    align-self: flex-start;
    align-items: center;
    border-radius: 4px;
    flex-direction: row;
    justify-content: center;
    border: 1px solid rgb(var(--dl-color-primary-100));
    cursor: pointer;
}

.profile_menu_top_header_upgrade:hover {
    background-color: rgba(var(--dl-color-primary-100), 0.09);
}

.profile_menu_top_header_upgrade_text {
    font-size: 11px;
    letter-spacing: 0.5px;
}

.profile_menu_top_header_upgrade_icon {
    width: 17px;
    height: 17px;
}

.profile_menu_top_states_count {
    color: rgba(var(--dl-color-white-always), 0.8);
    font-size: 11px;
    margin-top: 8px;
    font-weight: var(--dl-fontweight-500);
    letter-spacing: 0.3px;
}

.profile_menu_top_states_bar {
    height: 12px;
    display: flex;
    margin-top: 4px;
    align-items: flex-start;
    border-color: rgb(var(--dl-color-primary-100));
    border-width: 1px;
    border-radius: 6px;
    justify-content: flex-start;
}

.profile_menu_top_states_bar_fill {
    width: 60%;
    height: 100%;
    border-radius: 6px;
    background-color: rgb(var(--dl-color-primary-100));
}

.profile_menu_bottom {
    width: 100%;
    display: flex;
    padding: 16px 24px 12px;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.profile_menu_bottom_name {
    gap: 8px;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 16px;
    justify-content: flex-start;
}

.profile_menu_bottom_logo_wrapper {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    justify-content: center;
}

.profile_menu_bottom_name_text {
    color: rgb(var(--dl-color-text-black));
    font-size: 13px;
    font-weight: var(--dl-fontweight-500);
    letter-spacing: 0.3px;
}

.profile_menu_bottom_options_container {
    gap: 16px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}

.profile_menu_bottom_left {
    gap: 10px;
    width: 40%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.profile_menu_bottom_items_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
}

.profile_menu_bottom_item {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgb(var(--dl-color-text-black));
    flex-grow: 1;
    width: 100%;
    cursor: pointer;
}

.profile_menu_bottom_item:hover {
    color: rgb(var(--dl-color-primary-100));
}

.profile_menu_bottom_item_delete:hover {
    color: rgb(var(--dl-color-colors-red-error));
}

.profile_menu_bottom_text {
    color: currentColor;
    font-size: 13px;
    letter-spacing: 0.3px;
    white-space: nowrap;
}

.profile_menu_bottom_upgrade_button {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    background-color: rgb(var(--dl-color-primary-100));
    cursor: pointer;
}

.profile_menu_bottom_upgrade_button:hover {
    background-color: rgb(var(--dl-color-primary-300));
}

.profile_menu_bottom_upgrade_text {
    color: rgb(var(--dl-color-white-always));
    font-size: 13px;
    letter-spacing: 0.3px;
}

.profile_menu_bottom_separator_vertical {
    width: 1px;
    display: flex;
    align-items: flex-start;
    margin: 4px 0px;
    flex-direction: column;
    background-color: rgb(var(--dl-color-borders-separator-popup));
}

.profile_menu_bottom_right {
    gap: 10px;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.profile_menu_bottom_invite_button {
    display: flex;
    padding: 4px 10px;
    position: relative;
    align-items: center;
    border-color: rgb(var(--dl-color-primary-100));
    border-width: 1px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 16px;
}

.profile_menu_bottom_invite_button:hover {
    background-color: rgba(var(--dl-color-primary-100), 0.09);
}

.profile_menu_bottom_invite_button_icon {
    fill: rgb(var(--dl-color-primary-100));
    width: 14px;
    height: 14px;
}

.profile_menu_bottom_invite_button_text {
    font-size: 11px;
    margin-top: 2px;
    margin-left: 6px;
    letter-spacing: 0.5px;
}

.profile_menu_bottom_rewards_count {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    margin-top: 1px;
    margin-left: 4px;
    border-radius: 4px;
    justify-content: center;
    background-color: rgba(var(--dl-color-primary-100), 0.15);
    display: none;
}

.profile_menu_bottom_rewards_count_text {
    color: rgb(var(--dl-color-primary-100));
    font-size: 10px;
    font-weight: var(--dl-fontweight-500);
    letter-spacing: 0.3px;
}

.profile_menu_bottom_separator_horizontal {
    width: 100%;
    height: 1px;
    display: flex;
    align-items: flex-start;
    background-color: rgb(var(--dl-color-borders-separator-popup));
}

.profile_menu_bottom_help_center {
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.profile_menu_bottom_icon_help {
    fill: rgb(var(--dl-color-text-gray4));
    width: 12px;
    height: 12px;
    margin-left: 4px;
    margin-bottom: 1px;
}

.theme_toggle_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    width: 100%;
    cursor: pointer;
}