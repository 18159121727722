.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: calc(100% - 30px);
    position: relative;
}

.tab_row {
    height: 36px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0px 8px;
    flex-shrink: 0;
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator-popup));
}

.tab_row::-webkit-scrollbar {
	display: none
}

.tab_element {
    display: flex;
    padding: 0px 16px;
    position: relative;
    align-items: center;
    border-radius: 30px;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
}

.tab_element:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.tab_element_title_wrapper {
    gap: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab_element_title {
    font-size: 13px;
    font-weight: var(--dl-fontweight-500);
    letter-spacing: 0.5px;
    white-space: nowrap;
    line-height: 1;
}

.tab_element_title_circle {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(var(--dl-color-primary-300));
}

.tab_element_title_circle_text {
    font-size: 13px;
    font-weight: var(--dl-fontweight-500);
    color: rgb(var(--dl-color-white-always))
}

.tab_element_bar {
    left: 8px;
    right: 8px;
    bottom: 0px;
    height: 2px;
    position: absolute;
    border-radius: 2px;
    background-color: rgb(var(--dl-color-primary-100));
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: ease .2s;
}

.tab_content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    position: relative;
}

.simple_table_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    position: relative;
    flex: 1 1;
}

.simple_table_wrapper_content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.simple_table_wrapper_scroll {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
}

.simple_table_wrapper_scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.simple_table_wrapper_scroll_inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.simple_table_header {
    height: 30px;
    justify-content: flex-start;
    padding-left: 16px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.simple_table_header_right {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.simple_table_header_item {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 13px;
    letter-spacing: 0.5px;
    width: 33%;
}

.simple_table_header_item_left {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 13px;
    letter-spacing: 0.5px;
    width: 25%;
    max-width: 90px;
}

.simple_table_separator {
    position: relative;
    height: 30px;
    display: flex;
    padding: 0px 16px;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    flex-shrink: 0;
}

.simple_table_separator_text {
    font-size: 13px;
    letter-spacing: 0.5;
    line-height: 1;
}

.simple_table_separator_bar {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgb(var(--dl-color-primary-100));
}