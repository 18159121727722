.dashboard_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 0px;
    flex-grow: 1;
}

.container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 4px;
    overflow: auto;
    background-color: rgb(var(--dl-color-backgrounds-dashboard));
}

.container::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.container::-webkit-scrollbar-track-piece:start {
    margin-top: 4px;
    margin-left: 4px;
}

.top_row {
    height: 34px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
    padding: 0px 16px;
    flex-shrink: 0;
    border-bottom: 1px solid rgb(var(--dl-color-backgrounds-hover-light));
    border-top: 1px solid rgb(var(--dl-color-borders-separator-popup));
}

.delete_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    color: rgba(var(--dl-color-text-black), 0.6);
    cursor: pointer;
    transition: 0.2s;
}

.delete_icon_wrapper:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    color: rgb(var(--dl-color-colors-red));
}

.top_row_icon {
    width: 16px;
    height: 16px;
    fill: currentColor;
}

.gray_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    color: rgba(var(--dl-color-text-black), 0.6);
    cursor: pointer;
    transition: 0.2s;
}

.gray_icon_wrapper:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    color: rgba(var(--dl-color-text-black), 0.8);
}

.add_widget_popup {
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    padding: 8px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    border-radius: 6px;
    z-index: 10;
}

.add_widget_popup_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 8px;
    border-radius: 6px;
    height: 34px;
    white-space: nowrap;
    flex-wrap: nowrap;
    cursor: pointer;
    gap: 8px;
}

.add_widget_popup_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    color: rgb(var(--dl-color-black));
}

.add_widget_popup_item_text {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: currentColor;
    font-weight: var(--dl-fontweight-500);
}

.cut_width_provider {
    width: 2000px;
    flex-grow: 1;
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: visible;
}

.cut_width_provider::-webkit-scrollbar {
    display: none;
}

.full_width_provider {
    width: 5000px;
    background-color: rgb(var(--dl-color-backgrounds-dashboard));
}

.overview_item {
    width: 200px;
    height: 200px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid rgb(var(--dl-color-borders-separator));
    cursor: pointer;
}

.overview_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.popup_middle_container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    left: 0 !important;
    top: 0 !important;
    background-color: rgba(var(--dl-color-black-always), 0.64);
    overflow: hidden;
}