.container {
    position: relative;
    height: 0px;
    flex-grow: 1;
    overflow: hidden;
}

.scroll_container {
    position: relative;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.table_full_width_wrapper {
    width: 100%;
    height: 100%;
}

.table_dummy_background {
    border-top: 1px solid rgb(var(--dl-color-borders-separator-popup));
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    height: 38px;
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.layout_button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 31px;
    border: 1px solid rgb(var(--dl-color-borders-separator));
    border-right-width: 0px;
    border-top-color: rgb(var(--dl-color-borders-separator-popup));
    z-index: 2;
    background-color: rgb(var(--dl-color-backgrounds-hover1));
}

.layout_button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.table {
    display: inline-block;
    border-spacing: 0;
    z-index: 1;
    overflow: auto;
    height: 100%;
    width: 100%;
}

.table::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

.table::-webkit-scrollbar-track-piece:start {
    margin-top: 38px;
}

.header {
    position: sticky;
    top: 0;
    width: fit-content;
    z-index: 2;
}

.row {
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator));
    background-color: rgb(var(--dl-color-backgrounds-page));
    flex-direction: row;
    align-items: center;
}

.header_cell {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    cursor: default !important;
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
    flex-shrink: 0;
    border-top: 1px solid rgb(var(--dl-color-borders-separator-popup));
    overflow: hidden;
    z-index: 2;
}

.header_cell:last-child {
    border-right: none;
    pointer-events: none;
}

.header_cell:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.header_cell:first-child {
    position: sticky !important;
    left: 0;
    z-index: 3;
}

.header_cell_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 8px;
    height: 36px;
    position: relative;
    gap: 4px;
    width: 100%;
}

.header_text_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.header_text {
    color: rgb(var(--dl-color-text-gray3));
    font-size: 13px;
    letter-spacing: 0.5px;
    font-weight: var(--dl-fontweight-400);
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.header_subtext {
    color: rgb(var(--dl-color-text-black));
    font-size: 10px;
    letter-spacing: 0px;
    white-space: nowrap;
    margin-left: 1px;
}

.header_ticker_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex: 1 1 auto;
    position: relative;
}

.header_ticker_search_wrapper {
	gap: 6px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 8px;
    max-width: 140px;
    width: 100%;
    height: 26px;
    border-radius: 6px;
    border: 1px solid rgb(var(--dl-color-borders-separator));
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
    flex-shrink: 1;
}

.header_ticker_search_icon {
	fill: rgb(var(--dl-color-text-gray4));
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.header_ticker_search_input {
	display: flex;
    align-items: flex-start;
    font-size: 12px;
    font-weight: var(--dl-fontweight-300);
    letter-spacing: 0.3px;
    border: none;
    border-radius: 0px;
    width: 100%;
    height: 16px;
    overflow: hidden;
    line-height: 1;
    flex: 1 1 auto;
}

.header_ticker_search_input::placeholder {
	color: rgba(var(--dl-color-text-gray4), 0.63);
}

.header_ticker_search_delete {
	height: 16px;
    width: 16px;
    color: rgba(var(--dl-color-text-black), 0.6);
    border-radius: 4px;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_ticker_search_delete:hover {
	color: rgba(var(--dl-color-text-black), 0.8);
    background-color: rgb(var(--dl-color-backgrounds-hover2));
}

.header_ticker_search_delete_icon {
	width: 14px;
	height: 14px;
    fill: currentColor;
}

.header_sort_icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_sort_icon {
    width: 14px;
    height: 14px;
    fill: rgb(var(--dl-color-text-black));
}

.header_resizer_wrapper {
    width: 0px;
    height: 36px;
    position: relative;
}

.header_resizer {
    width: 4px;
    height: 100;
    z-index: 4;
    touch-action: none;
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0
}

.header_resizer_active {
    background: rgba(var(--dl-color-text-black), 0.8);
}

.row_separator {
    border-bottom: 1px solid rgb(var(--dl-color-borders-separator));
    width: 100%;
    height: 1px;
}

.row_cell {
    background-color: rgb(var(--dl-color-backgrounds-gray-light));
    cursor: default;
    flex-shrink: 0;
    overflow: hidden;
}

.row_cell:first-child {
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
    z-index: 1;
    position: sticky !important;
    left: 0;
}

.row_cell_border_right {
    border-right: 1px solid rgb(var(--dl-color-borders-separator));
}

.cell_wrapper {
    padding: 6px 8px;
    cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
}

.logo_wrapper {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    color: rgba(var(--dl-color-text-black), 0.8);
    flex-shrink: 0;
}

.logo {
    height: 15px;
    fill: currentColor;
}

.ticker_wrapper {
    width: 50px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(var(--dl-color-backgrounds-hover2));
    border-radius: 4px;
    flex-shrink: 0;
}

.ticker_text {
    font-size: 12px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.news_dummy {
    width: 15px;
}

.news_provider {
    font-size: 12px;
    color: rgb(var(--dl-color-text-gray3));
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.news_text {
    font-size: 12px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.news_time {
    font-size: 11px;
    color: rgb(var(--dl-color-text-gray3));
}

.cell_text {
    font-size: 13px;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}