.container_right {
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    height: 28px;
    gap: 8px;
    padding-right: 6px;
}

.icon_right_wrapper {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    color: rgba(var(--dl-color-text-black), 0.8);
}

.icon_right_wrapper:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1));
    color: rgb(var(--dl-color-black));
}

.icon_right {
    fill: currentColor;
    width: 19px;
    height: 19px;
}