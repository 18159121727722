.avatar_wrapper {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    justify-content: center;
}

.avatar_image {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: rgb(var(--dl-color-white));
    fill: rgb(var(--dl-color-white));
}