.date-picker {
    z-index: 2001;
}

.ant-picker {
    width: 100%;
    box-shadow: none !important;
    border-color: rgb(var(--dl-color-borders-separator-popup)) !important;
    cursor: pointer !important;
    height: 100%;
    background: transparent !important;
}

.ant-picker:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover1)) !important;
}

.ant-picker input {
    cursor: pointer !important;
    color: rgb(var(--dl-color-text-black)) !important;
    font-size: 13px !important;
    font-weight: var(--dl-fontweight-400) !important;
    letter-spacing: 0.5px !important;
    line-height: 1 !important;
}

.ant-picker input::placeholder {
    cursor: pointer !important;
    color: rgba(var(--dl-color-text-gray2), 0.85) !important;
    font-size: 13px !important;
    font-weight: var(--dl-fontweight-400) !important;
    letter-spacing: 0.5px !important;
    line-height: 1 !important;
}

.ant-picker-panel-container {
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31) !important;
    background: rgb(var(--dl-color-backgrounds-white-on-white)) !important;
}

.ant-picker:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker-focused {
    border-color: rgb(var(--dl-color-primary-100)) !important;
}

.ant-picker-cell-inner:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3)) !important;
}

.ant-picker-input {
    color: rgb(var(--dl-color-text-black)) !important;
    height: 32px !important;
}

.ant-picker-suffix {
    color: rgba(var(--dl-color-text-black), 0.8) !important;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
    display: none;
}

.ant-picker-time-panel-cell {
    margin-inline: 0px !important;
    margin: auto !important;
}

.ant-picker-time-panel-cell-inner {
    margin: auto !important;
    text-align: center !important;
    padding: 0px !important;
}

.ant-picker-header {
    border-color: rgb(var(--dl-color-borders-separator)) !important;
}

.ant-picker-header>button {
    height: 22px;
    margin: auto;
    display: flex;
    position: relative;
}

.ant-picker-header-view button {
    color: rgb(var(--dl-color-text-black)) !important;
    font-weight: var(--dl-fontweight-600) !important;
}

.ant-picker-header>button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
    box-shadow: none;
}

.ant-picker-header-view>button {
    height: 28px;
    margin-top: 6px;
    padding: 4px 4px !important;
}

.ant-picker-header-view>button:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
    box-shadow: none;
    color: rgb(var(--dl-color-primary-100)) !important;
}

.ant-picker-year-btn {
    margin-left: 0px !important;
}

.ant-picker-content th {
    color: rgb(var(--dl-color-text-black)) !important;
    font-weight: var(--dl-fontweight-500) !important;
}

.ant-picker:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker .ant-picker-input>input {
    color: rgb(var(--dl-color-text-black));
}

.ant-picker-cell-in-view .ant-picker-cell-inner,
.ant-picker-time-panel-cell-inner {
    color: rgb(var(--dl-color-text-black)) !important;
}

.ant-picker-time-panel-cell-inner:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3)) !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
    color: rgb(var(--dl-color-white-always)) !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner:hover {
    background-color: rgb(var(--dl-color-primary-300)) !important;    
}

.ant-picker-cell-selected .ant-picker-cell-inner::before {
    border: none !important;
}

.ant-picker-time-panel-column,
.ant-picker-footer {
    border-color: rgb(var(--dl-color-borders-separator)) !important;
}

.ant-picker-today-btn,
.ant-picker-now-btn {
    color: rgb(var(--dl-color-primary-100)) !important;
}

.ant-picker-today-btn:hover,
.ant-picker-now-btn:hover {
    color: rgb(var(--dl-color-primary-300)) !important;
}

.ant-picker-month-btn:hover,
.ant-picker-year-btn:hover {
    color: rgb(var(--dl-color-text-black)) !important;
}

.ant-picker-header-next-btn {
    padding-right: 4px !important;
}

.ant-picker-header-super-next-btn {
    padding-left: 2px !important;
}

.ant-picker-header-prev-btn {
    padding-left: 4px !important;
}

.ant-picker-header-super-prev-btn {
    padding-right: 2px !important;
}

.ant-picker-now {
    margin-bottom: 4px;
}

.ant-picker-now-btn {
    margin-left: 2px;
}

.ant-btn-primary {
    background-color: rgb(var(--dl-color-primary-100)) !important;
    box-shadow: none !important;
}

.ant-btn-primary:hover {
    background-color: rgb(var(--dl-color-primary-300)) !important;
    box-shadow: none !important;
}

.ant-btn-primary:disabled {
    color: rgba(var(--dl-color-text-black), 0.5) !important;
    background-color: transparent !important;
    border-color: rgb(var(--dl-color-text-gray3)) !important;
}

.ant-picker-ranges {
    padding: 4px 12px 12px !important;
}

.ant-picker-ranges li {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: rgba(var(--dl-color-primary-100), 0.15) !important;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner:hover {
    background: rgba(var(--dl-color-primary-100), 0.35) !important;
}