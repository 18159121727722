.container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 6px;
    z-index: 1000;
    background-color: rgb(var(--dl-color-backgrounds-topnav));
}