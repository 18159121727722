.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
}

.settings_container {
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    height: 22px;
    padding: 0px 4px;
    border-radius: 11px;
    position: absolute;
    right: 4px;
    top: -4px;
    box-shadow: 0px 1px 3px 0px rgba(var(--dl-color-black-always), 0.26);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease .3s;
    z-index: 11;
}

.settings_expanded {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    transition: ease .3s;
}

.settings_separator {
    height: 8px;
    width: 1px;
    background-color: rgba(var(--dl-color-text-black), 0.4);
    margin: 0px 4px 0px 7px;
}

.settings_icon_wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
}

.settings_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: rgba(var(--dl-color-text-black), 0.75);
    border-radius: 6px;
    cursor: pointer;
    flex-shrink: 0;
}

.settings_icon_container:hover {
    color: rgb(var(--dl-color-text-black));
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.settings_icon {
    fill: currentColor;
    height: 14px;
    width: 14px;
}

.settings_popup {
    position: relative;
    z-index: 1;
    padding: 8px 0px;
}

.settings_popup_inner {
    padding: 12px 0px 8px;
    box-shadow: 1px 2px 15px 0px rgba(var(--dl-color-shadow), 0.31);
    background-color: rgb(var(--dl-color-backgrounds-white-on-white));
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.settings_top_row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 16px;
    margin-bottom: 8px;
}

.settings_popup_header {
    font-size: 12px;
    font-weight: var(--dl-fontweight-400);
    color: rgb(var(--dl-color-text-gray3));
    letter-spacing: 0.5px;
}

.settings_popup_help_icon {
	width: 12px;
	height: 12px;
	cursor: pointer;
	fill: rgba(var(--dl-color-text-black), 0.57)
}

.settings_popup_help_icon:hover {
	fill: rgba(var(--dl-color-text-black), 0.8)
}

.settings_popup_section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: stretch;
}

.settings_popup_item {
    display: flex;
    height: 34px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0px 8px;
    white-space: nowrap;
    flex-wrap: nowrap;
    gap: 16px;
    margin: 0px 8px;
    border-radius: 6px;
}

.settings_popup_item:hover {
    background-color: rgb(var(--dl-color-backgrounds-hover3));
}

.settings_popup_item_hover_red:hover {
    color: rgb(var(--dl-color-colors-red-bright));
}

.settings_popup_item_left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    color: currentColor;
}

.settings_popup_item_icon {
    fill: rgba(var(--dl-color-text-black), 0.7);
    width: 13px;
    height: 13px;
}

.settings_popup_item_text {
    font-size: 13px;
    color: currentColor;
    line-height: 1;
}

.settings_popup_item_icon_arrow {
    width: 15px;
    height: 15px;
    fill: rgba(var(--dl-color-text-black), 0.7);
}

.settings_popup_separator {
    width: 100%;
    height: 1px;
    background-color: rgb(var(--dl-color-borders-separator));
    margin: 8px 0px;
}

.connection_circle_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 8px;
    flex-shrink: 0;
    overflow: hidden;
    transition: ease .3s;
    height: 100%;
}

.connection_circle_wrapper {
    border-radius: 8px;
    gap: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transition: ease .3s;
}

.connection_circle_background {
    cursor: pointer;
    border-radius: 10px;
}

.connection_circle {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    border: 1px solid black;
    flex-shrink: 0;
}

.connection_circle_cross {
    width: 100%;
    height: 100%;
    opacity: 0.6;
}

.title {
    font-size: 20px;
    font-weight: var(--dl-fontweight-400);
    letter-spacing: 0.5px;
    margin-left: 16px;
}