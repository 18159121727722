.page_wrapper {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgb(var(--dl-color-backgrounds-page));
}

.top_bar_wrapper {
    top: 0px;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 100;
    position: absolute;
    align-items: flex-start;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 0px;
    justify-content: space-between;
}

.top_bar_left {
    gap: 8px;
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 30px;
    object-fit: cover;
    border-radius: 8px;
}

.logo_text {
    font-size: 28px;
    font-style: normal;
    font-weight: var(--dl-fontweight-800);
    line-height: 1;
}

.top_bar_right {
    gap: 16px;
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.top_bar_right_text {
    font-size: 14px;
    align-self: center;
    font-style: normal;
    font-weight: var(--dl-fontweight-500);
}

.top_bar_right_button {
    width: 80px;
}

.form_wrapper {
    top: 0px;
    flex: 0 0 auto;
    left: 0px;
    right: 0px;
    width: 95%;
    bottom: 0px;
    height: auto;
    margin: auto;
    display: flex;
    z-index: 100;
    position: relative;
    max-width: 420px;
    align-items: center;
    padding-top: 48px;
    flex-direction: column;
    justify-content: center;
}

.form_container {
    left: 0px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    height: auto;
    display: flex;
    z-index: 100;
    align-self: stretch;
    box-shadow: 0px 24px 64px 1px rgba(var(--dl-color-shadow), 0.2);
    align-items: flex-start;
    margin: auto;
    padding: 48px 32px;
    border-radius: 12px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgb(var(--dl-color-backgrounds-white));
}

.header {
    font-size: 22px;
    letter-spacing: 0.2px;
    font-weight: var(--dl-fontweight-600);
    margin-bottom: 4px;
}

.subheader {
    font-size: 11px;
    font-weight: var(--dl-fontweight-500);
    color: rgb(var(--dl-color-text-gray2));
    margin-bottom: 24px;
}

.verification {
    margin-bottom: 0px;
}

.password_input_container {
    width: 100%;
}

.verification_text {
    margin-bottom: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.verification_text_email {
    font-weight: var(--dl-fontweight-500);
    font-size: 16px;
    align-self: center;
}

.input_row {
    flex: 0 0 auto;
    display: flex;
    align-self: stretch;
    align-items: stretch;
    margin-bottom: 12px;
    flex-direction: column;
    position: relative;
}

.label_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.input_field {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    position: relative;
    align-self: stretch;
    align-items: stretch;
}

.input_label {
    color: rgba(var(--dl-color-text-gray4), 0.8);
    font-size: 12px;
    position: absolute;
    left: 17px;
    top: 13px;
    transition: 0.1s;
}

.input_label_focus {
    font-size: 9px;
    top: 5px;
}

.textinput {
    width: 100%;
    height: 42px;
    font-size: 12px;
    align-self: stretch;
    transition: 0.1s;
    border-color: rgba(var(--dl-color-black), 0.25);
    padding: 0px 16px;
}

.textinput:focus {
    box-shadow: 0px 0px 8px -4px rgb(var(--dl-color-primary-100));
    border-color: rgb(var(--dl-color-primary-100)) !important;
    outline-width: none;
}

.textinput:hover {
    box-shadow: 0px 0px 8px -2px rgba(var(--dl-color-shadow), 0.4);
}

.border_error {
    border-color: rgb(var(--dl-color-colors-red-error)) !important;
}

.border_error:focus {
    border-color: rgb(var(--dl-color-colors-red-error)) !important;
    box-shadow: 0px 0px 8px -4px rgba(var(--dl-color-colors-red-error), 0.3);
}

.textinput_content {
    padding-top: 12px;
}

.show_password_wrapper {
    cursor: pointer;
    position: absolute;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 16px;
}

.show_password {
    color: rgb(var(--dl-color-primary-100));
}

.show_password:hover {
    text-decoration: underline;
}

.forgot_password {
    align-self: flex-end;
    margin-top: 4px;
    margin-right: 8px;
}

.forgot_password_text {
    color: rgb(var(--dl-color-primary-100));
    cursor: pointer;
}

.forgot_password_text:hover {
    text-decoration: underline;
}

.forgot_password_move_up {
    margin-top: -16px;
}

.submit_button {
    height: 42px;
    font-size: 14px;
    align-self: stretch;
    font-style: normal;
    transition: 0.1s;
    border-width: 0px;
    width: 100%;
    margin: 4px 0px;
}

.button_error {
    cursor: auto;
    background-color: rgb(var(--dl-color-text-gray5));
}

.button_error:hover {
    background-color: rgb(var(--dl-color-text-gray5));
    box-shadow: none;
}

.button_outline {
    background-color: transparent;
    border-color: rgb(var(--dl-color-primary-100));
    color: rgb(var(--dl-color-primary-100));
}

.button_outline:hover {
    background-color: rgba(var(--dl-color-primary-100), 0.09);
}

.page_switch {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
}

.page_switch_link {
    color: rgb(var(--dl-color-primary-100));
}

.page_switch_link:hover {
    text-decoration: underline;
}

.separator_container {
    margin-top: 32px;
    gap: 8px;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.separator_line {
    margin-top: 2px;
    flex: 0 0 auto;
    width: 50%;
    height: 1px;
    flex-shrink: 1;
    display: flex;
    align-items: flex-start;
    background-color: rgb(var(--dl-color-text-gray4));
}

.separator_text {
    color: rgb(var(--dl-color-text-gray4));
    flex-shrink: 0;
}

.sso_input_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 16px;
}

.sso_icon_row {
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
}

.sso_icon_wrapper {
    flex: 0 0 auto;
    width: 50%;
    height: 38px;
    display: flex;
    align-items: center;
    border-color: rgba(var(--dl-color-text-black), 0.3);
    border-width: 1px;
    border-radius: 4px;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 1;
}

.sso_icon_wrapper:hover {
    box-shadow: 0px 0px 8px -2px rgba(var(--dl-color-shadow), 0.4);
}

.border_primary {
    border-color: rgb(var(--dl-color-primary-100));
    box-shadow: none !important;
}

.sso_icon {
    width: auto;
    height: 20px;
    object-fit: cover;
}

.sso_submit_wrapper {
    margin-top: 16px;
    width: 100%;
}

@media(max-width: 767px) {
    .top_bar_right_text {
        display: none;
    }
}

@media(max-width: 479px) {
    .form_container {
        padding-left: 30px;
        padding-right: 30px;
    }
}